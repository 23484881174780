import React, { useEffect, useState } from 'react';
import { Container, Table, Button } from 'react-bootstrap'
import EditModal from './editModal'
import { ArrowRepeat } from 'react-bootstrap-icons'

function WorkspaceEditor(props) {

  const [workspaces, setWorkspaces] = useState([])
  const [workingWorkspace, setWorkingWorkspace] = useState(null)
  const [modalVisible, setModalVisible] = useState("")  
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setWorkspaces(props.workspaces)
    setLoading(props.loading)
  }, [props.workspaces, props.token, props.loading])

  let showModal = (modal, workspace) => {
    setWorkingWorkspace(workspace)
    setModalVisible(modal)
  }

  let createWorkspace = () => {
    let emptyWorkspace = {
      id: null,
      name: "",
      version: "",
      packagePath: ""
    }

    showModal("editor", emptyWorkspace)
    
  }

  let workspaceRows = workspaces.map((ws) => {
    return <tr key={ws.name}><td>{ws.name}</td><td><Button size="sm" onClick={() => { showModal("editor", ws) }}>Edit</Button></td></tr>
  })

  return (
    <Container fluid style={{paddingTop: "10px"}}>         
      <div style={{margin: "10px", textAlign: "right"}}>        
        <Button variant="success" onClick={() => createWorkspace()}>New Workspace</Button>
      </div>
      <h2>Workspaces</h2>
      <Table>
        <thead>
          <tr>
            <th>Workspace</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{workspaceRows}</tbody>
      </Table>      

      {modalVisible == "editor" && <EditModal token={props.token} setModalVisible={setModalVisible} workspace={workingWorkspace} reloadData={props.reloadData} />}      

    </Container>
  );
}

export default WorkspaceEditor