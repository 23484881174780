import React, { useEffect, useState } from 'react';
import { Container, Table, Button, ProgressBar, Row, Col } from 'react-bootstrap'
import EditModal from './editModal'
import { ArrowRepeat, CircleFill } from 'react-bootstrap-icons'
import { cancelBackfillRun, saveBackfillRun } from '../../services/lakehouse-api'

function BackfillRunEditor(props) {

  const [backfillRuns, setBackfillRuns] = useState([])
  const [tableConfigs, setTableConfigs] = useState([])
  const [workingBackfillRun, setWorkingBackfillRun] = useState(null)
  const [modalVisible, setModalVisible] = useState("")
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState("")

  console.log(props)

  useEffect(() => {
    setToken(props.token)
    setBackfillRuns(props.backfillRuns)
    setTableConfigs(props.tableConfigs)
    setLoading(props.loading)
  }, [props.backfillRuns, props.tableConfigs, props.loading, props.token])

  let showModal = (modal, backfillRun) => {
    setWorkingBackfillRun(backfillRun)
    setModalVisible(modal)
  }

  let cancelRun = (backfillRun) => {
    cancelBackfillRun(token, backfillRun)
    props.reloadData()
  }

  let approveRun = (backfillRun) => {
    backfillRun.status = "queued"
    saveBackfillRun(token, backfillRun)
    props.reloadData()
  }

  let createBackfillRun = () => {
    let emptyBackfillRun = {
      "id": null,
      "backfillConfigId": null,
      "startDate": "",
      "endDate": "",
      "runDependencies": false,
      "tenants": "*",
      "domains": "*",
      "parentBackfillRunId": null
    }

    showModal("editor", emptyBackfillRun)

  }

  let r = backfillRuns.sort((a, b) => {
    if (a.timeQueued === '') return 1
    if (a.timeQueued < b.timeQueued) return -1
    else return 1
  })


  let backfillRunRows = backfillRuns.sort((a, b) => {
    if (a.timeQueued === '') return -1
    if (b.timeQueued === '') return 1
    if (a.timeQueued > b.timeQueued) return -1
    else return 1
  }).map((br) => {
    let config = tableConfigs.find((bc) => bc.id == br.tableConfigId)
    if (config == null) return null

    let progress = null

    if (br.totalTasks > 0) {
      let completePercent = (br.completedTasks / br.totalTasks) * 100
      let runningPercent = (br.runningTasks / br.totalTasks) * 100
      let dupePercent = (br.duplicateTasks / br.totalTasks) * 100
      let cancelPercent = (br.cancelledTasks / br.totalTasks) * 100
      let failedPercent = (br.failedTasks / br.totalTasks) * 100

      progress = <ProgressBar>
        <ProgressBar striped variant="success" label={`${Math.round(completePercent)}%`} animated={false} now={completePercent} key={1} />
        <ProgressBar striped variant="info" animated={false} now={dupePercent} key={2} />
        <ProgressBar striped variant="warning" animated={false} now={cancelPercent} key={3} />
        <ProgressBar striped variant="primary" animated={true} now={runningPercent} key={4} />
        <ProgressBar striped variant="danger" animated={true} now={failedPercent} key={5} />
      </ProgressBar>
    }

    let fillColor = "#ddd"
    switch (br.status) {
      case "running":
        fillColor = "#0d6efd"
        break
      case "complete":
        fillColor = "#198754"
        break;
      case "cancelled":
        fillColor = "#ffc107"
        break;
      case "failed":
        fillColor = "#ff5656"
        break;

    }

    return <tr key={br.id}>
      <td><CircleFill style={{ color: fillColor }} /> {br.status}</td>
      <td>{config.name}</td>
      <td>{config.zone}</td>
      <td>{progress}</td>
      <td>{br.timeQueued === '' ? 'No Tasks Created Yet' : br.timeQueued}</td>
      <td><b>{br.startDate}</b> to <b>{br.endDate}</b></td>
      <td>
        {br.status != 'cancelled' && br.status != 'complete' && (br.queuedTasks > 0 || br.totalTasks == 0) && <Button variant="danger" size="sm" onClick={() => cancelRun(br)}>Cancel Run</Button>} 
        {br.status == 'pending' && props.isAdmin && <Button variant="success" size="sm" style={{marginLeft: "10px"}} onClick={() => approveRun(br)}>Approve Run</Button>}
      </td>
    </tr>
  })

  return (
    <Container fluid>
      <div style={{ margin: "10px", textAlign: "right" }}>        
        <Button variant="success" onClick={() => createBackfillRun()}>New Backfill Run</Button>
      </div>

      <Table>
        <thead>
          <tr>
            <th>Status</th>
            <th>Table Configuration</th>
            <th>Zone</th>
            <th>Progress</th>
            <th>Time Queued</th>
            <th>Backfill Dates</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{backfillRunRows}</tbody>
      </Table>

      {/* <Row style={{marginTop: "50px"}}>
        <Col sm="2"><ProgressBar striped variant="primary" animated={true} now={100} /> Running</Col>
        <Col sm="2"><ProgressBar striped variant="info" animated={false} now={100} /> Duplicate</Col>
        <Col sm="2"><ProgressBar striped variant="danger" animated={false} now={100} /> Failed</Col>
        <Col sm="2"><ProgressBar striped variant="warning" animated={false} now={100} /> Cancelled</Col>
        <Col sm="2"><ProgressBar striped variant="success" animated={false} now={100} /> Completed</Col>
      </Row> */}

      {modalVisible == "editor" && <EditModal token={token} tableConfigs={tableConfigs} backfillRun={workingBackfillRun} reloadData={props.reloadData} />}

    </Container>
  );
}

export default BackfillRunEditor