import React, { useState, useEffect } from 'react';
import { Table, Button, Container, Row, Col, Form, Modal, InputGroup } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { saveGoldTable } from '../../services/lakehouse-api'
import { XCircleFill, Hash } from 'react-bootstrap-icons'

function GoldMergeModal(props) {
  const [workingTable, setWorkingTable] = useState(null);
  const [newKeyField, setNewKeyField] = useState("")
  const [newPartitionField, setNewPartitionField] = useState("")


  useEffect(() => {
    setWorkingTable(props.table)
  }, [props.table])

  let handleClose = () => {
    props.setModalVisible("")
    setWorkingTable(null)
  }
  let setMergeSchedule = (schedule) => {
    const newWorkingTable = { ...workingTable }
    newWorkingTable.scheduleType = schedule
    newWorkingTable.mergeRules.schedule = schedule
    setWorkingTable(newWorkingTable)
  }

  let removeKeyField = (idx) => {
    const newWorkingTable = { ...workingTable }
    newWorkingTable.mergeRules.keyFields.splice(idx, 1)
    setWorkingTable(newWorkingTable)
  }

  let addKeyField = () => {
    const newWorkingTable = { ...workingTable }
    newWorkingTable.mergeRules.keyFields.push(newKeyField)
    setWorkingTable(newWorkingTable)
  }

  let removePartitionField = (idx) => {
    const newWorkingTable = { ...workingTable }
    newWorkingTable.mergeRules.partitionFields.splice(idx, 1)
    setWorkingTable(newWorkingTable)
  }

  let addPartitionField = () => {
    const newWorkingTable = { ...workingTable }
    newWorkingTable.mergeRules.partitionFields.push(newPartitionField)
    setWorkingTable(newWorkingTable)
  }


  let saveWorkingTable = async () => {
    await saveGoldTable(props.token, workingTable)
    await props.reloadTables()
    handleClose()
  }


  if (workingTable == null) return null

  let mergeKeyFields = workingTable.mergeRules.keyFields.map((f, i) => {
    return <InputGroup className="mb-3">
      <InputGroup.Text id="basic-addon1">{i}</InputGroup.Text>
      <Form.Control locked type="text" value={f} />
      <Button variant="danger" id="button-addon2" onClick={() => removeKeyField(i)}>
        Remove
      </Button>

    </InputGroup>

  })

  let mergePartitionFields = workingTable.mergeRules.partitionFields.map((f, i) => {
    return <InputGroup className="mb-3">
      <InputGroup.Text id="basic-addon1">{i}</InputGroup.Text>
      <Form.Control locked type="text" value={f} />
      <Button variant="danger" id="button-addon2" onClick={() => removePartitionField(i)}>
        Remove
      </Button>

    </InputGroup>

  })


  let fieldOptions = workingTable.fields.map((field) => {
    if (field.path == field.name && field.type !== "struct" && field.type !== "array-struct")
      return <option value={field.path}>{field.path}</option>
  })

  let partitionFieldOptions = workingTable.fields.map((field) => {
    if (field.path == field.name && workingTable.mergeRules.keyFields.includes(field.path))
      return <option value={field.path}>{field.path}</option>
  })

  return (
    <Modal
      show={workingTable != null}
      onHide={handleClose}
      backdrop="static"
      keyboard={true}
      fullscreen={false}
      size="lg" >
      <Modal.Header closeButton>
        <Modal.Title>Editing {workingTable.name} Filters</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>

          <Row>
            <Col>
              <div style={{ marginBottom: "50px" }}>
                {/* <h2>Merge Rules</h2>
                <Form>
                  <Form.Group>
                    <Form.Label>Merge Method</Form.Label>
                    <Form.Select value={workingTable.mergeRules.method} onChange={(e) => setMergeMethod(e.target.value)}>
                      <option>Choose Merge Method</option>
                      <option value="all">All Records</option>
                      <option value="newest">Replace Newest (All Time)</option>
                      <option value="newestByDate">Replace Newest (Per Day)</option>
                      <option value="newestByHour">Replace Newest (Per Day/Hour)</option>
                      <option value="mergeNewest">Merge Newest (All Time)</option>
                      <option value="mergeNewestByDate">Merge Newest (Per Day)</option>
                      <option value="mergeNewestByHour">Merge Newest (Per Day/Hour)</option>
                    </Form.Select>
                  </Form.Group> */}

                <h2>Merge Rules</h2>

                <Form.Group>
                  <Form.Label>Merge Schedule</Form.Label>
                  <Form.Select value={workingTable.mergeRules.schedule} onChange={(e) => setMergeSchedule(e.target.value)}>
                    <option>Choose Merge Schedule</option>
                    <option value="hourly">Hourly</option>
                    <option value="daily">Daily</option>
                  </Form.Select>
                </Form.Group>


                <div style={{marginTop: "20px"}}>
                  <Form.Label>Key Fields</Form.Label>
                  {mergeKeyFields}

                  <InputGroup className="mb-3">
                    <Form.Select value={newKeyField} onChange={(e) => setNewKeyField(e.target.value)}>
                      <option>Choose Key Fields</option>
                      {fieldOptions}
                    </Form.Select>
                    <Button variant="success" id="button-addon2" onClick={() => addKeyField()}>
                      Add
                    </Button>
                  </InputGroup>

                </div>


                <div>
                  <Form.Label>Partition Fields</Form.Label>
                  {mergePartitionFields}

                  <InputGroup className="mb-3">
                    <Form.Select value={newPartitionField} onChange={(e) => setNewPartitionField(e.target.value)}>
                      <option>Choose Partition Fields</option>
                      {partitionFieldOptions}
                    </Form.Select>
                    <Button variant="success" id="button-addon2" onClick={() => addPartitionField()}>
                      Add
                    </Button>
                  </InputGroup>

                </div>

              </div>
            </Col>
          </Row>

        </Container>




      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={saveWorkingTable}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default GoldMergeModal;