import React, { useEffect, useState } from 'react';
import { Container, Table, Button, Modal, Row, Col, Form } from 'react-bootstrap'
import EditModal from './editModal'
import DependencyModal from './dependencyModal'
import { Table as TableIcon, FunnelFill, BoxArrowInRight, GearFill, ArrowRepeat } from 'react-bootstrap-icons'

function TableConfigEditor(props) {

  const [subjectAreas, setSubjectAreas] = useState([])
  const [tableConfigs, setTableConfigs] = useState([])    
  const [workingTableConfig, setWorkingTableConfig] = useState(null)
  const [modalVisible, setModalVisible] = useState("")  
  const [token, setToken] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setToken(props.token)
    setSubjectAreas(props.subjectAreas)
    setTableConfigs(props.tableConfigs)
    setLoading(props.loading)    
  }, [props.tableConfigs, props.token, props.loading])

  let showModal = (modal, tableConfig) => {
    setWorkingTableConfig(tableConfig)
    setModalVisible(modal)    
  }

  let createTableConfig = () => {
    let emptyTableConfig = {
      "id": null,
      "subjectAreaId": 0,
      "name": "",
      "tableName": "",
      "resourceConfig": "",
      "lookbackDays": 0,
      "account": "cdm",
      "cdmSDKVersion": "",
      "requiresTenants": true,
      "requiresDomains": false,
      "customParameters": null,
      "forceSequential": false,
      "zone": ""
  }

    showModal("editor", emptyTableConfig)
    
  }


  let tableConfigRows = tableConfigs.map((bc) => {
    let sa = subjectAreas.find((sa) => sa.id == bc.subjectAreaId)
    return <tr key={bc.name}>
      <td>{bc.name}</td>
      <td>{bc.zone}</td>
      <td>{bc.tableName}</td>
      <td>{sa.name}</td>
      <td>{sa.version}</td>
      <td>{bc.cdmSDKVersion}</td>
      <td style={{textAlign: "right"}}>
        <Button size="sm" onClick={() => { showModal("editor", bc) }}>Edit</Button>{' '}
        <Button size="sm" onClick={() => { showModal("dependency", bc) }}>Dependencies</Button>
      </td>
    </tr>
  })
 
  
  return (
    <Container fluid style={{paddingTop: "10px"}}>      
    <div style={{margin: "10px", textAlign: "right"}}>      
      <Button variant="success" onClick={() => createTableConfig()}>New Table Configuration</Button>
    </div>
      <h2>Table Configurations</h2>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Zone</th>
            <th>Table</th>
            <th>Subject Area</th>
            <th>Subject Area Version</th>            
            <th>CDM SDK Version</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{tableConfigRows}</tbody>
      </Table>      

      {modalVisible == "editor" && <EditModal token={token} setModalVisible={setModalVisible} tableConfig={workingTableConfig} subjectAreas={subjectAreas} reloadData={props.reloadData} />}
      {modalVisible == "dependency" && <DependencyModal token={token} setModalVisible={setModalVisible} tableConfig={workingTableConfig} tableConfigs={tableConfigs} reloadData={props.reloadData} />}

    </Container>
  );
}

export default TableConfigEditor