import React, { useState, useEffect } from 'react';
import { Table, Button, Container, Row, Col, Form, Modal, Alert } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { saveGoldTable } from '../../services/lakehouse-api'
import { XCircleFill, Hash } from 'react-bootstrap-icons'

function GoldFieldsModal(props) {
  const [workingTable, setWorkingTable] = useState(null);
  const [newName, setNewName] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [newSensitivity, setNewSensitivity] = useState("");
  const [newParent, setNewParent] = useState("");
  const [newType, setNewType] = useState("");

  useEffect(() => {
    setWorkingTable(props.table)
  }, [props.table])

  let handleClose = () => {
    props.setModalVisible("")
    setWorkingTable(null)
  }

  let addNewField = () => {
    let newPath = (newParent == "") ? newName : newParent + "." + newName

    let fields = []
    if (workingTable.fields != null)
      fields = [...workingTable.fields]

    let s = fields.find((i) => i.path == newPath)
    if (s != null) return false
    let newField = {
      "name": newName,
      "path": newPath,
      "type": newType,
      "parent": newParent,
      "sensitivity": newSensitivity,
      "description": newDescription,
      "isNew": true
    }
    const updateTable = { ...workingTable }
    updateTable.fields = [...fields, newField];

    setWorkingTable(updateTable)
  }

  let deleteNewField = (path) => {
    const updateTable = { ...workingTable }
    updateTable.fields = updateTable.fields.filter(function (r) {
      return r.path !== path;
    });
    setWorkingTable(updateTable)
  }

  let saveWorkingTable = async () => {
    await saveGoldTable(props.token, workingTable)
    await props.reloadTables()
    handleClose()
  }


  if (workingTable == null) return null  

  let fields = []
  if (workingTable.fields != null)
    fields = [...workingTable.fields]


  let fieldRows = fields.sort((f, s) => {
    if (f.path >= s.path) return 1
    else return -1
  }).map((field) => {
    return <tr key={field.path}>
      <td>{(field.parent != "") ? (<span style={{ color: "#aaa" }}> {field.parent + "."}</span>) : ""}<b>{field.name}</b></td>
      <td>{field.sensitivity}</td>
      <td>{field.type}</td>
      <td>{field.isNew === true && <XCircleFill style={{ color: "#dc0000", cursor: "pointer" }} onClick={() => deleteNewField(field.path)} />}</td></tr>
  })


  let parentOptions = fields.map((field) => {
    if (field.type === "struct" || field.type === "array-struct")
      return <option value={field.path}>{field.path}</option>
  })

  let addValid = false 
  if (newName != "" && newDescription != "" && newSensitivity != "" && newType != "") {
    addValid = true
  } 


  return (
    <Modal
      show={workingTable != null}
      onHide={handleClose}
      backdrop="static"
      keyboard={true}
      fullscreen={false}
      size="lg" >
      <Modal.Header closeButton>
        <Modal.Title>Editing {workingTable.name} Structure</Modal.Title>
      </Modal.Header>
      <Modal.Body>      

        <Container fluid>
          <Row>
            <Col>
              <Table>
                <thead>
                  <tr>
                    <th>Field Name</th>
                    <th>Sensitivity</th>
                    <th>Type</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {fieldRows}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{ backgroundColor: "#ddd", padding: "10px", marginTop: "50px" }}>
                <h4>Add New Field</h4>
                <Form>
                  <Form.Group style={{marginBottom: "10px"}}>                    
                    <Form.Control type="text" placeholder="New Field Name" value={newName} onChange={(e) => setNewName(e.target.value)} />
                  </Form.Group>
                  <Form.Group style={{marginBottom: "10px"}}>                    
                    <Form.Select value={newType} onChange={(e) => setNewType(e.target.value)}>
                      <option>Choose Field Type</option>
                      <option value="string">String</option>
                      <option value="integer">Integer</option>
                      <option value="double">Double</option>
                      <option value="boolean">Boolean</option>
                      <option value="timestamp">Timestamp</option>
                      <option value="date">Date</option>
                      <option value="array-struct">Array (Struct)</option>
                      <option value="array-integer">Array (Integer)</option>
                      <option value="array-string">Array (String)</option>
                      <option value="array-double">Array (Float)</option>
                      <option value="struct">Struct</option>
                    </Form.Select>
                  </Form.Group>
                  {/* <Form.Group style={{marginBottom: "10px"}}>                    
                    <Form.Select value={newParent} onChange={(e) => setNewParent(e.target.value)}>
                      <option>Choose Field Parent</option>
                      <option value="">Top Level (No Parent)</option>
                      {parentOptions}
                    </Form.Select>
                  </Form.Group> */}
                  <Form.Group style={{marginBottom: "10px"}}>                    
                    <Form.Control as="textarea" style={{height: "100px"}} placeholder="Description" type="text" value={newDescription} onChange={(e) => setNewDescription(e.target.value)} />
                  </Form.Group>
                  <Form.Group style={{marginBottom: "10px"}}>                    
                    <Form.Select value={newSensitivity} onChange={(e) => setNewSensitivity(e.target.value)}>
                      <option>Choose Field Sensitivity</option>
                      <option value="internal">Internal</option>
                      <option value="sensitive">Sensitive</option>
                      <option value="restricted">Restricted</option>
                    </Form.Select>
                  </Form.Group>
                  <Button type="button" disabled={!addValid} onClick={addNewField}>
                    Add Field
                  </Button>

                </Form>
              </div>
            </Col>
          </Row>
        </Container>




      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={saveWorkingTable}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default GoldFieldsModal;