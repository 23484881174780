import React, { useEffect, useState } from 'react';
import { Container, OverlayTrigger, Tooltip, Button, Modal, Row, Col, Form, Alert, Card } from 'react-bootstrap'
import GoldQueryModal from '../goldTableEditor/goldQueryModal'
import GoldFieldsModal from './goldFieldsModal'
import GoldRunModal from './goldRunModal'
import GoldMergeModal from './goldMergeModal'
import { saveGoldTable } from '../../services/lakehouse-api'
import { Table as TableIcon, FunnelFill, BoxArrowInRight, GearFill, ArrowRepeat, ArrowUpCircle } from 'react-bootstrap-icons'
import { AiOutlineConsoleSql } from 'react-icons/ai'
import { VscGitMerge } from 'react-icons/vsc'
import { ImTable2 } from 'react-icons/im'
import { IoPlaySharp } from 'react-icons/io5'
function GoldTableEditor(props) {

  const [goldTables, setGoldTables] = useState([])
  const [workingGoldTable, setWorkingGoldTable] = useState(null)
  const [modalVisible, setModalVisible] = useState("")
  const [showNewGoldTable, setShowNewGoldTable] = useState(null)
  const [newGoldTableName, setNewGoldTableName] = useState("");
  const [newGoldTableDesc, setNewGoldTableDesc] = useState("");
  const [token, setToken] = useState("")
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState("")

  useEffect(() => {
    setToken(props.token)
    setLoading(props.loading)
    setGoldTables(props.goldTables)
  }, [props.token, props.loading, props.goldTables])

  if (props.workspace == null) {
    return (
      <Container fluid>
        <Alert variant="primary" style={{ marginTop: "25px" }}>
          Choose a workspace to edit streaming tables
        </Alert>
      </Container>
    )
  }


  let showGoldTableModal = (modal, tableConfig) => {
    setWorkingGoldTable(tableConfig)
    setModalVisible(modal)
  }

  let updateNewGoldTableName = (name) => {
    var name = name.replace(/[^a-z|_|\-|0-9]+/g, "");
    setNewGoldTableName(name)
  }

  let updateNewGoldTableDesc = (desc) => {
    setNewGoldTableDesc(desc)
  }

  let addNewGoldTable = () => {

    let newTable = {
      "workspaceId": parseInt(props.workspace.id),
      "name": newGoldTableName,
      "version": 1,
      "scheduleType": "hourly",
      "table": {
        "workspaceId": parseInt(props.workspace.id),
        "name": newGoldTableName,
        "description": newGoldTableDesc,
        "sql": [],
        "fields": [],
        "mergeRules": {
          "method": "all", "keyFields": [], "partitionFields": [], "schedule": "hourly"
        }
      }
    }

    saveGoldTable(token, newTable.table)
    setGoldTables([...goldTables, newTable])
    setShowNewGoldTable(false)
  }

  let filteredTables = goldTables

  if (filter != '') {
    filteredTables = filteredTables.filter((t) => {
      return (t.name.toLowerCase().includes(filter.toLowerCase()))
    })
  }

  let goldTableRows = filteredTables.map((st) => {


    return <Row style={{ boxShadow: "0px 0px 13px -5px rgba(0,0,0,0.6)", border: "1px solid #eee", padding: "10px 0px", marginTop: "10px" }}>
      <Col lg="3" md="4" sm="12" xs="12" style={{ marginBottom: "5px" }}>
        <div style={{ fontWeight: "bold" }}>{st.name}</div>
        <div style={{ color: "#666", fontSize: ".9em" }}>rev{st.version}</div>
      </Col>
      <Col style={{ textAlign: "right" }}>
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Table Structure</Tooltip>}>
          <span><ImTable2 style={{ marginRight: "7px", cursor: "pointer", fontSize: "1.3em", color: "#777" }} title="Table Structure" onClick={() => { showGoldTableModal("gold-fields", st.table) }} /></span>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Query Editor</Tooltip>}>
          <span><AiOutlineConsoleSql style={{ marginRight: "7px", cursor: "pointer", fontSize: "1.3em", color: "#777" }} title="Query Editor" onClick={() => { showGoldTableModal("gold-query", st.table) }} /></span>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Merge Rules</Tooltip>}>
          <span><VscGitMerge style={{ marginRight: "7px", cursor: "pointer", fontSize: "1.3em", color: "#777" }} title="Merge Rules" onClick={() => { showGoldTableModal("gold-merge", st.table) }} /></span>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Run Now</Tooltip>}>
          <span><IoPlaySharp style={{ marginRight: "7px", cursor: "pointer", fontSize: "1.3em", color: "#777" }} title="Run Now" onClick={() => { showGoldTableModal("gold-run", st.table) }} /></span>
        </OverlayTrigger>

      </Col>
    </Row >

  })


  let activateAdd = false
  if (newGoldTableName.length > 3 && newGoldTableDesc != "") {
    activateAdd = true
  }

  return (
    <Container fluid>
      <div style={{ margin: "10px", textAlign: "right" }}>
        <Row>
          <Col sm="8">
            <Form.Control type="text" placeholder="Filter By Table Name" value={filter} onChange={(e) => setFilter(e.target.value)} />
          </Col>
          <Col sm="4">
            <Button variant="success" onClick={() => setShowNewGoldTable(true)}>New Reporting Table</Button>
          </Col>
        </Row>

      </div>

      <Container fluid style={{ maxHeight: "calc(100vh - 60px)", paddingBottom: "100px", overflow: "scroll" }}>
        <Row style={{ backgroundColor: "#333", padding: "10px 0px", color: "white" }}>
          <Col lg="12">Reporting Tables</Col>
        </Row>
        {goldTableRows}
      </Container>

      {modalVisible == "gold-query" && <GoldQueryModal table={workingGoldTable} token={props.token} setModalVisible={setModalVisible} reloadTables={props.reloadData} />}
      {modalVisible == "gold-fields" && <GoldFieldsModal table={workingGoldTable} token={props.token} setModalVisible={setModalVisible} reloadTables={props.reloadData} />}
      {/* {modalVisible == "silver-settings" && <SilverSettingsModal table={workingSilverTable} token={props.token} setModalVisible={setModalVisible} dataTypes={props.dataTypes} reloadTables={props.reloadData} />}
      {modalVisible == "silver-filters" && <SilverFiltersModal table={workingSilverTable} token={props.token} setModalVisible={setModalVisible} reloadTables={props.reloadData} />} */}
      {modalVisible == "gold-merge" && <GoldMergeModal table={workingGoldTable} token={props.token} setModalVisible={setModalVisible} reloadTables={props.reloadData} />}
      {modalVisible == "gold-run" && <GoldRunModal table={workingGoldTable} token={props.token} setModalVisible={setModalVisible} reloadTables={props.reloadData} />}

      <Modal
        show={showNewGoldTable}
        onHide={() => setShowNewGoldTable(false)}
        backdrop="static"
        keyboard={false}
        fullscreen={false} >
        <Modal.Header closeButton>
          <Modal.Title>Add New Reporting Table</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form.Group style={{ marginBottom: "10px" }}>
            <Form.Control type="text" placeholder="New Gold Table Name" value={newGoldTableName} onChange={(e) => updateNewGoldTableName(e.target.value)} />
          </Form.Group>

          <Form.Group>
            <Form.Control as="textarea" type="text" placeholder="New Gold Table Description" value={newGoldTableDesc} onChange={(e) => updateNewGoldTableDesc(e.target.value)} style={{ height: "100px" }} />
          </Form.Group>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowNewGoldTable(false)}>
            Close
          </Button>
          <Button variant="primary" disabled={!activateAdd} onClick={addNewGoldTable}>
            Add Table
          </Button>
        </Modal.Footer>
      </Modal>

    </Container>
  );
}

export default GoldTableEditor