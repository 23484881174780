import React, { useState, useEffect } from 'react';
import { Container, Row, FormControl, InputGroup, Table, Badge } from 'react-bootstrap'
import SchemaModal from './schemaModal';
import { getSchemaList, getSchema } from '../../services/lakehouse-api'

function SchemaEditor(props) {

  const [filterString, setFilterString] = useState("");
  const [dataTypes, setDataTypes] = useState({});
  const [loading, setLoading] = useState(true)
  const [selectedSchema, setSelectedSchema] = useState(null)
  const [showModal, setShowModal] = useState(false)  

  useEffect(async () => {
    setDataTypes(await getSchemaList(props.token))
    setLoading(false)
  }, [])

  // convert object to array
  let schemaArray = Object.keys(dataTypes).map((dt, k) => {
    let v = dataTypes[dt]
    return v
  })
  
  schemaArray.sort((f, s) => {
    if (f.name.toLowerCase() >= s.name.toLowerCase()) return 1
    else return -1
  })

  let schemaRows = schemaArray.map((s) => {
    if (filterString != "" && s.name.toLowerCase().indexOf(filterString) == -1)
      return null
    return <tr style={{cursor: "pointer"}} key={s.name} onClick={() => selectSchema(s)}><td>{s.name}</td><td>version {s.version}</td><td><Badge>{s.source}</Badge></td></tr>
  })

  if (loading) {
    return (<div style={{textAlign: "center"}}>Updating Schemas, Please Wait.</div>)
  }

  const handleClose = () => {
    setShowModal(false)
    setSelectedSchema(null)
  }

  const selectSchema = async (s) => {      
    console.log(s)
    var ss = await getSchema(props.token, s.name)
    console.log('got schema')
    console.log(ss)
    setSelectedSchema(ss)
    setShowModal(true)
  }

  return (
    <div>
      <SchemaModal show={showModal} handleClose={handleClose} schema={selectedSchema} />
      <Container fluid style={{height: "calc(100vh - 60px)", padding : "25px", overflow: "scroll"}}>
        <Row>
          <InputGroup className="mb-3">
            <InputGroup.Text>
              filter
            </InputGroup.Text>
            <FormControl value={filterString} onChange={(e) => setFilterString(e.target.value)} />
          </InputGroup>
        </Row>
        <Row style={{ "overflow": "scroll", "maxHeight": "100vh" }}>
          <Table>
            <thead><tr><th>Name</th><th>Current Version</th><th>Source</th></tr></thead>
            <tbody>{schemaRows}</tbody>
          </Table>
        </Row>
      </Container>
    </div>
  );
}

export default SchemaEditor;