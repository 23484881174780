import React, { useState, useEffect } from 'react';
import { Table, Button, Container, Row, Col, Form, Modal, InputGroup } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { saveSilverTable } from '../../services/lakehouse-api'
import { XCircleFill, Hash } from 'react-bootstrap-icons'

function SilverMergeModal(props) {
  const [workingTable, setWorkingTable] = useState(null);
  const [newMergeField, setNewMergeField] = useState("")


  useEffect(() => {
    setWorkingTable(props.table)
  }, [props.table])

  let handleClose = () => {
    props.setModalVisible("")
    setWorkingTable(null)
  }
  let setMergeMethod = (method) => {
    const newWorkingTable = { ...workingTable}
    newWorkingTable.mergeRules.method = method
    
    if (method == 'all') {
      newWorkingTable.mergeRules.fields = []  
    }
    setWorkingTable(newWorkingTable)
  }

  let removeMergeField = (idx) => {
    const newWorkingTable = { ...workingTable}    
    newWorkingTable.mergeRules.fields.splice(idx, 1)    
    setWorkingTable(newWorkingTable)
  }

  let addMergeField = () => {
    const newWorkingTable = { ...workingTable}        
    newWorkingTable.mergeRules.fields.push(newMergeField)  
    setWorkingTable(newWorkingTable)
  }

  let saveWorkingTable = async () => {
    await saveSilverTable(props.token, workingTable)
    await props.reloadTables()
    handleClose()
  }


  if (workingTable == null) return null

  let mergeFields = workingTable.mergeRules.fields.map((f, i) => {
    return <InputGroup className="mb-3">
      <InputGroup.Text id="basic-addon1">{i}</InputGroup.Text>
      <Form.Control locked type="text" value={f} />
      <Button variant="danger" id="button-addon2" onClick={() => removeMergeField(i)}>
        Remove
      </Button>

    </InputGroup>

  })

  let fieldOptions = workingTable.fields.map((field) => {
    if (field.path == field.name && field.type !== "struct" && field.type !== "array-struct")
      return <option value={field.path}>{field.path}</option>
  })

  let saveEnabled = true

  if (workingTable.mergeRules.method != 'all' && mergeFields.length <= 0) {
    saveEnabled = false
  }

  return (
    <Modal
      show={workingTable != null}
      onHide={handleClose}
      backdrop="static"
      keyboard={true}
      fullscreen={false}
      size="lg" >
      <Modal.Header closeButton>
        <Modal.Title>Editing {workingTable.name} Filters</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>

          <Row>
            <Col>
              <div style={{ marginBottom: "50px" }}>
                
                <Form>
                  <Form.Group>
                    <h2>Merge Method</h2>    
                    <Form.Select value={workingTable.mergeRules.method} onChange={(e) => setMergeMethod(e.target.value)}>
                      <option>Choose Merge Method</option>
                      <option value="all">All Records</option>
                      <option value="newest">Replace Newest (All Time)</option>
                      <option value="newestByDate">Replace Newest (Per Day)</option>
                      <option value="newestByHour">Replace Newest (Per Day/Hour)</option>
                      <option value="mergeNewest">Merge Newest (All Time)</option>
                      <option value="mergeNewestByDate">Merge Newest (Per Day)</option>
                      <option value="mergeNewestByHour">Merge Newest (Per Day/Hour)</option>
                    </Form.Select>
                  </Form.Group>

                  {workingTable.mergeRules.method != 'all' &&
                    <div style={{marginTop: "25px"}}>
                      <h2>Primary Key</h2>
                      <p>When merging data, these fields will be used to determine what constitutes a unique row.  At least one field is required.</p>
                      {mergeFields}

                      <InputGroup className="mb-3">
                        <Form.Select value={newMergeField} onChange={(e) => setNewMergeField(e.target.value)}>
                          <option>Choose Additional Primay Key Field</option>
                          <option value="_id">_id</option>
                          <option value="_tenant">_tenant</option>
                          <option value="_dataType">_dataType</option>
                          {fieldOptions}
                        </Form.Select>
                        <Button variant="success" id="button-addon2" onClick={() => addMergeField()}>
                          Add
                        </Button>
                      </InputGroup>

                    </div>

                  }
                </Form>
              </div>
            </Col>
          </Row>

        </Container>




      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" disabled={!saveEnabled} onClick={saveWorkingTable}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SilverMergeModal;