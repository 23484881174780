import React, { useEffect, useState } from 'react';
import { Container, Table, Button } from 'react-bootstrap'
import EditModal from './editModal'
import DependencyModal from './dependencyModal'
import { ArrowRepeat } from 'react-bootstrap-icons'

function SubjectAreaEditor(props) {

  const [subjectAreas, setSubjectAreas] = useState([])
  const [workingSubjectArea, setWorkingSubjectArea] = useState(null)
  const [modalVisible, setModalVisible] = useState("")
  const [token, setToken] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setToken(props.token)
    setSubjectAreas(props.subjectAreas)
    setLoading(props.loading)
  }, [props.subjectAreas, props.token, props.loading])

  let showModal = (modal, subjectArea) => {
    setWorkingSubjectArea(subjectArea)
    setModalVisible(modal)
  }

  let createSubjectArea = () => {
    let emptySubjectArea = {
      id: null,
      name: "",
      version: "",
      packagePath: ""
    }

    showModal("editor", emptySubjectArea)
    
  }

  let subjectAreaRows = subjectAreas.map((sa) => {
    return <tr key={sa.id}><td>{sa.name}</td><td>{sa.version}</td><td>{sa.packagePath}</td><td><Button size="sm" onClick={() => { showModal("editor", sa) }}>Edit</Button> <Button size="sm" onClick={() => { showModal("dependency", sa) }}>Dependencies</Button></td></tr>
  })

  return (
    <Container fluid style={{paddingTop: "10px"}}>         
      <div style={{margin: "10px", textAlign: "right"}}>        
        <Button variant="success" onClick={() => createSubjectArea()}>New Subject Area</Button>
      </div>
      <h2>Subject Areas</h2>
      <Table>
        <thead>
          <tr>
            <th>Subject Area</th>
            <th>Current Version</th>
            <th>Package Path</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{subjectAreaRows}</tbody>
      </Table>      

      {modalVisible == "editor" && <EditModal token={token} setModalVisible={setModalVisible} subjectArea={workingSubjectArea} reloadData={props.reloadData} />}
      {modalVisible == "dependency" && <DependencyModal token={token} setModalVisible={setModalVisible} subjectArea={workingSubjectArea} subjectAreas={subjectAreas} reloadData={props.reloadData} />}

    </Container>
  );
}

export default SubjectAreaEditor