import React, { useState, useEffect } from 'react';
import { Table, Button, Container, Row, Col, Form, Modal } from 'react-bootstrap'
import { saveWorkspace } from '../../services/lakehouse-api'

function EditModal(props) {
  const [workingWorkspace, setWorkingWorkspace] = useState(null);  

  useEffect(() => {
    setWorkingWorkspace(props.workspace)    
  }, [props.workspace])

  if (workingWorkspace == null) {
    return null
  }

  let updateWorkspace = (field, value) => {
    let newWorkspace = {...workingWorkspace}

    if (field == "workspaceKey") value = value.replace(/[^a-z|_|\-|0-9]+/g, "");

    newWorkspace[field] = value
    setWorkingWorkspace(newWorkspace)
  }

  let handleClose = () => {    
    setWorkingWorkspace(null)
    props.setModalVisible("")
  }

  let saveWorkingWorkspace = async () => {
    await saveWorkspace(props.token, workingWorkspace)
    await props.reloadData(props.token)
    handleClose()
  }

  return (
    <Modal
      show={workingWorkspace != null}
      onHide={handleClose}
      backdrop="static"
      keyboard={true}
      fullscreen={false} >
      <Modal.Header closeButton>
        <Modal.Title>Editing {workingWorkspace.name} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Form.Group style={{marginBottom: "10px"}}>
            <Form.Label>Workspace Name</Form.Label>
            <Form.Control type="text" placeholder="Enter A Workspace Name" value={workingWorkspace.name} onChange={(e) => updateWorkspace('name', e.target.value)} />
          </Form.Group> 
          <Form.Group style={{marginBottom: "10px"}}>
            <Form.Label>Workspace Key</Form.Label>
            <Form.Control type="text" placeholder="Enter Workspace Key" value={workingWorkspace.workspaceKey} onChange={(e) => updateWorkspace('workspaceKey', e.target.value)} />
          </Form.Group> 
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={saveWorkingWorkspace}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default EditModal;