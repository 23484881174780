import React, { useEffect, useState } from 'react';
import { Container, OverlayTrigger, Tooltip, Button, Modal, Row, Col, Form, Alert, Card } from 'react-bootstrap'
import SilverFieldsModal from '../silverTableEditor/silverFieldsModal'
import SilverSettingsModal from './silverSettingsModal'
import SilverFiltersModal from './silverFiltersModal'
import SilverMergeModal from './silverMergeModal'
import { saveSilverTable } from '../../services/lakehouse-api'
import { Table as TableIcon, FunnelFill, BoxArrowInRight, GearFill, ArrowRepeat, ArrowUpCircle } from 'react-bootstrap-icons'
import { VscGitMerge } from 'react-icons/vsc'
import { ImTable2 } from 'react-icons/im'
import { BsFunnel } from 'react-icons/bs'
import { FaExchangeAlt } from 'react-icons/fa'
function SilverTableEditor(props) {

  const [silverTables, setSilverTables] = useState([])
  const [workingSilverTable, setWorkingSilverTable] = useState(null)
  const [modalVisible, setModalVisible] = useState("")
  const [showNewSilverTable, setShowNewSilverTable] = useState(null)
  const [newSilverTableName, setNewSilverTableName] = useState("");
  const [newSilverTableDesc, setNewSilverTableDesc] = useState("");
  const [token, setToken] = useState("")
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState("")

  useEffect(() => {
    setToken(props.token)
    setLoading(props.loading)
    setSilverTables(props.silverTables)
  }, [props.token, props.loading, props.silverTables])

  if (props.workspace == null) {
    return (
      <Container fluid>
        <Alert variant="primary" style={{ marginTop: "25px" }}>
          Choose a workspace to edit streaming tables
        </Alert>
      </Container>
    )
  }


  let showSilverTableModal = (modal, tableConfig) => {
    setWorkingSilverTable(tableConfig)
    setModalVisible(modal)
  }

  let updateNewSilverTableName = (name) => {
    var name = name.replace(/[^a-z|_|\-|0-9]+/g, "");
    setNewSilverTableName(name)
  }

  let updateNewSilverTableDesc = (desc) => {
    setNewSilverTableDesc(desc)
  }

  let addNewSilverTable = () => {

    let newTable = {
      "workspaceId": parseInt(props.workspace.id),
      "name": newSilverTableName,
      "version": 1,
      "enabled": false,
      "table": {
        "workspaceId": parseInt(props.workspace.id),
        "name": newSilverTableName,
        "description": newSilverTableDesc,
        "fields": [],
        "dataTypes": {},
        "filters": [],
        "enabled": false,
        "mergeRules": {
          "method": "all", "fields": []
        }
      }
    }

    saveSilverTable(token, newTable.table)
    setSilverTables([...silverTables, newTable])
    setShowNewSilverTable(false)
  }

  let toggleEnabled = (workspaceId, tableName) => {
    
    let workingTables = [...silverTables]
    
    let t = workingTables.find((tt) => tt.workspaceId == workspaceId && tt.name == tableName)
    
    let newValue = !t.enabled

    t.enabled = newValue
    t.table.enabled = newValue

    setSilverTables(workingTables)
    saveSilverTable(token, t.table)
            
  }

  let filteredTables = silverTables

  if (filter != '') {
    filteredTables = filteredTables.filter((t) => {

      return (t.name.toLowerCase().includes(filter.toLowerCase()) || (Object.keys(t.table.dataTypes).findIndex((k) => k.toLowerCase().includes(filter.toLowerCase())) >= 0))
    })
  }

  let silverTableRows = filteredTables.map((st) => {

    let sources = Object.keys(st.table.dataTypes)

    let sourceItems = sources.map((s) => {
      return <div>{s}</div>
    })

    if (sourceItems.length == 0) {
      sourceItems = "No Sources Defined Yet"
    }

    return <Row style={{ boxShadow: "0px 0px 13px -5px rgba(0,0,0,0.6)", border: "1px solid #eee", padding: "10px 0px", marginTop: "10px" }}>
      <Col lg="4" md="12" sm="12" xs="12" style={{ marginBottom: "5px" }}>
        <div style={{ fontWeight: "bold" }}>{st.name}</div>
        <div style={{ color: "#666", fontSize: ".9em" }}>rev{st.version}</div></Col>
      <Col lg="4" md="6" sm="12" xs="12" style={{ marginBottom: "5px" }}>
        <div style={{ fontWeight: "bold" }}>Sources</div>
        <div style={{ color: "#666", fontSize: ".9em" }}>{sourceItems}</div>
      </Col>
      <Col lg="2" md="6" sm="12" xs="12" style={{ marginBottom: "5px" }}>
        <div style={{ fontWeight: "bold" }}>Merge Method</div>
        <div style={{ color: "#666", fontSize: ".9em" }}>{st.table.mergeRules.method}</div>
      </Col>
      <Col lg="2" md="12" sm="12" xs="12" style={{ marginBottom: "5px", textAlign: "right" }}>
        <div>
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Table Structure</Tooltip>}>
            <span><ImTable2 style={{ marginRight: "7px", cursor: "pointer", fontSize: "1.3em", color: "#777" }} title="Table Structure" onClick={() => { showSilverTableModal("silver-fields", st.table) }} /></span>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Streaming Transformations</Tooltip>}>
            <span><FaExchangeAlt style={{ marginRight: "7px", cursor: "pointer", fontSize: "1.3em", color: "#777" }} title="Streaming Transformations" onClick={() => { showSilverTableModal("silver-settings", st.table) }} /></span>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Data Filters</Tooltip>}>
            <span><BsFunnel style={{ marginRight: "7px", cursor: "pointer", fontSize: "1.3em", color: "#777" }} title="Data Filters" onClick={() => { showSilverTableModal("silver-filters", st.table) }} /></span>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Merge Rules</Tooltip>}>
            <span><VscGitMerge style={{ marginRight: "7px", cursor: "pointer", fontSize: "1.3em", color: "#777" }} title="Merge Rules" onClick={() => { showSilverTableModal("silver-merge", st.table) }} /></span>
          </OverlayTrigger>
        </div>

        <div style={{marginTop: "20px"}}>
          Enabled <Form.Check
            type="switch"
            id="custom-tenants"
            style={{display: "inline", marginLeft: "10px"}}
            checked={st.table.enabled}
            onChange={(e) => { toggleEnabled(st.workspaceId, st.name) }}
          />
        </div>
      </Col>
    </Row>



  })


  let activateAdd = false
  if (newSilverTableName.length > 3 && newSilverTableDesc != "") {
    activateAdd = true
  }

  return (
    <Container fluid>
      <div style={{ margin: "10px", textAlign: "right" }}>
        <Row>
          <Col sm="8">
            <Form.Control type="text" placeholder="Filter By Table Or Source" value={filter} onChange={(e) => setFilter(e.target.value)} />
          </Col>
          <Col sm="4">
            <Button variant="success" onClick={() => setShowNewSilverTable(true)}>New Streamed Table</Button>
          </Col>
        </Row>
      </div>

      <Container fluid style={{ maxHeight: "calc(100vh - 60px)", paddingBottom: "100px", overflow: "scroll" }}>
        <Row style={{ backgroundColor: "#333", padding: "10px 0px", color: "white" }}>
          <Col lg="12">Streaming Tables</Col>
          {/* <Col lg="4" className="d-none d-lg-block">Sources</Col>
          <Col lg="3" className="d-none d-lg-block">Merge Method</Col>
          <Col lg="2" className="d-none d-lg-block">Actions</Col> */}
        </Row>
        {silverTableRows}
      </Container>

      {modalVisible == "silver-fields" && <SilverFieldsModal table={workingSilverTable} token={props.token} setModalVisible={setModalVisible} reloadTables={props.reloadData} />}
      {modalVisible == "silver-settings" && <SilverSettingsModal table={workingSilverTable} token={props.token} setModalVisible={setModalVisible} dataTypes={props.dataTypes} reloadTables={props.reloadData} />}
      {modalVisible == "silver-filters" && <SilverFiltersModal table={workingSilverTable} token={props.token} setModalVisible={setModalVisible} reloadTables={props.reloadData} />}
      {modalVisible == "silver-merge" && <SilverMergeModal table={workingSilverTable} token={props.token} setModalVisible={setModalVisible} reloadTables={props.reloadData} />}

      <Modal
        show={showNewSilverTable}
        onHide={() => setShowNewSilverTable(false)}
        backdrop="static"
        keyboard={false}
        fullscreen={false} >
        <Modal.Header closeButton>
          <Modal.Title>Add New Streamed Table</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form.Group style={{ marginBottom: "10px" }}>
            <Form.Control type="text" placeholder="New Streamed Table Name" value={newSilverTableName} onChange={(e) => updateNewSilverTableName(e.target.value)} />
          </Form.Group>

          <Form.Group>
            <Form.Control as="textarea" type="text" placeholder="New Streamed Table Description" value={newSilverTableDesc} onChange={(e) => updateNewSilverTableDesc(e.target.value)} style={{ height: "100px" }} />
          </Form.Group>



        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowNewSilverTable(false)}>
            Close
          </Button>
          <Button variant="primary" disabled={!activateAdd} onClick={addNewSilverTable}>
            Add Table
          </Button>
        </Modal.Footer>
      </Modal>

    </Container >
  );
}

export default SilverTableEditor