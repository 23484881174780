import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Container, Row, Col, Badge } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { saveSchema } from '../../services/lakehouse-api'

function SchemaModal(props) {

  const [workingSchema, setWorkingSchema] = useState(props.schema);
  const [showNewField, setShowNewField] = useState(false);
  const [newName, setNewName] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [newSensitivity, setNewSensitivity] = useState("");
  const [newParent, setNewParent] = useState("");
  const [newType, setNewType] = useState("");

  useEffect(() => {
    setWorkingSchema(props.schema)
  }, [props.schema])

  if (workingSchema == null) return null
  console.log(workingSchema)
  let fieldRows = workingSchema.fields.sort((f, s) => {
    if (f.path >= s.path) return 1
    else return -1
  }).map((field) => {
    return <tr key={field.path}><td>{(field.parent != "") ? (<span style={{ color: "#aaa" }}> {field.parent + "."}</span>) : ""}<b>{field.name}</b></td><td>{field.description}</td><td>{field.sensitivity}</td><td>{field.type}</td></tr>
  })

  let addNewField = () => {

    let newField = {
      "name": newName,
      "path": (newParent == "") ? newName : newParent + "." + newName,
      "type": newType,
      "parent": newParent,
      "sensitivity": newSensitivity,
      "description": newDescription

    }

    const updateFields = [...workingSchema.fields, newField];

    const newWorkingSchema = { ...workingSchema, fields: updateFields };

    setWorkingSchema(newWorkingSchema)

  }

  let saveWorkingSchema = () => {
    saveSchema(workingSchema)
    props.handleClose()
  }

  let parentOptions = workingSchema.fields.map((field) => {
    if (field.type === "struct" || field.type === "array")
      return <option value={field.path}>{field.path}</option>
  })



  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
      fullscreen={true} >
      <Modal.Header closeButton>
        <Modal.Title>{workingSchema.name}
          <div style={{ fontSize: ".8em", color: "#666" }}>version {workingSchema.version} - updated {workingSchema.created}</div>
          <div>
            {workingSchema.source == 'lakehouse' && <div><Badge bg="success">{workingSchema.source}</Badge></div>}
            {workingSchema.source == 'make' && <div><Badge bg="danger">{workingSchema.source}</Badge></div>}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Container fluid>
          <Row>
            <Col>

              <Table>
                <thead>
                  <tr>
                    <th>Field Name</th>
                    <th>Description</th>
                    <th>Sensitivity</th>
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody>
                  {fieldRows}
                </tbody>
              </Table>


            </Col>
            <Col>

              {workingSchema.source == 'lakehouse' &&
                <div style={{ backgroundColor: "#ddd", padding: "50px" }}>

                  <h4>Add New Field</h4>
                  <Form>


                    <Form.Group>
                      <Form.Label>Field Name</Form.Label>
                      <Form.Control type="text" value={newName} onChange={(e) => setNewName(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Type</Form.Label>
                      <Form.Select value={newType} onChange={(e) => setNewType(e.target.value)}>
                        <option>Choose Type</option>
                        <option value="string">String</option>
                        <option value="integer">Integer</option>
                        <option value="float">Float</option>
                        <option value="boolean">Boolean</option>
                        <option value="array-struct">Array (Struct)</option>
                        <option value="array-integer">Array (Integer)</option>
                        <option value="array-string">Array (String)</option>
                        <option value="array-float">Array (Float)</option>
                        <option value="struct">Struct</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Parent</Form.Label>
                      <Form.Select value={newParent} onChange={(e) => setNewParent(e.target.value)}>
                        <option>Choose Parent</option>
                        <option value="">Top Level</option>
                        {parentOptions}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Description</Form.Label>
                      <Form.Control type="text" value={newDescription} onChange={(e) => setNewDescription(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Sensitivity</Form.Label>
                      <Form.Select value={newSensitivity} onChange={(e) => setNewSensitivity(e.target.value)}>
                        <option>Choose Sensitivity Level</option>
                        <option value="internal">Internal</option>
                        <option value="sensitive">Sensitive</option>
                        <option value="restricted">Restricted</option>
                      </Form.Select>
                    </Form.Group>
                    <Button type="button" onClick={addNewField}>
                      Add Field
                    </Button>

                  </Form>
                </div>
              }
            </Col>
          </Row>
        </Container>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Close
        </Button>
        {workingSchema.source == 'lakehouse' && <Button variant="primary" onClick={saveWorkingSchema}>
          Save Changes
        </Button>}
      </Modal.Footer>
    </Modal>

  );
}

export default SchemaModal;