import React, { useState, useEffect } from 'react';
import { Table, Button, Container, Row, Col, Form, Modal, InputGroup } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';

function GoldTableRunModal(props) {
  const [workingTable, setWorkingTable] = useState(null);

  useEffect(() => {
    setWorkingTable(props.table)
  }, [props.table])

  let handleClose = () => {
    props.setModalVisible("")
    setWorkingTable(null)
  }

  if (workingTable == null) return null
  
  return (
    <Modal
      show={workingTable != null}
      onHide={handleClose}
      backdrop="static"
      keyboard={true}
      fullscreen={false}
      size="lg" >
      <Modal.Header closeButton>
        <Modal.Title>Schedule Run</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          
        </Container>

      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Schedule Table Run
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default GoldTableRunModal;