import React, { useState, useEffect } from 'react';
import { Table, Button, Container, Row, Col, Form, Modal } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { 
  getTableConfigDependencies,
  saveTableConfigDependency, 
  deleteTableConfigDependency } from '../../services/lakehouse-api'

function DependencyModal(props) {  
  const [tableConfigs, setTableConfigs] = useState(null);
  const [workingTableConfig, setWorkingTableConfig] = useState(null);
  const [dependencies, setDependencies] = useState(null);
  const [newDependency, setNewDependency] = useState(null);
  const [token, setToken] = useState("")

  useEffect(async () => {
    setToken(props.token)
    setDependencies(await getTableConfigDependencies(props.token, props.tableConfig.id))
    setWorkingTableConfig(props.tableConfig)
    setTableConfigs(props.tableConfigs)      
  }, [props.tableConfig, props.tableConfigs, props.token])

  if (dependencies == null || workingTableConfig == null || tableConfigs == null) {
    return null
  }

  let handleClose = () => {
    setWorkingTableConfig(null)
    props.setModalVisible("")
  }

  let deleteDependency = async (dep) => {
    let deps = [...dependencies]

    let index = deps.findIndex((d) => d.dependentTableConfigId == dep.dependentTableConfigId)
    deps.splice(index, 1)

    setDependencies(deps)
    await deleteTableConfigDependency(token, dep)
  }

  let addDependency = async () => {
    
    if (newDependency == "") return false
    
    let deps = [...dependencies]

    let newDep = {
      tableConfigId: workingTableConfig.id,
      dependentTableConfigId: parseInt(newDependency)
    }

    deps.push(newDep)
    setDependencies(deps)
    await saveTableConfigDependency(token, newDep)
  }

  let saOptions = tableConfigs.map((sa) => {
    if (dependencies.find((dsa) => dsa.dependentTableConfigId == sa.id) || workingTableConfig.id == sa.id) return null
    return <option value={sa.id}>{sa.name}</option>
  })
  
  let depRows = dependencies.map((d) => {
    let dsa = tableConfigs.find((bc) => bc.id == d.dependentTableConfigId)

    return <Row>
      <Col>{dsa.name}</Col>
      <Col><Button variant="danger" onClick={() => deleteDependency(d)}>Remove</Button></Col>
    </Row>
  })

  return (
    <Modal
      show={workingTableConfig != null}
      onHide={handleClose}
      backdrop="static"
      keyboard={true}
      fullscreen={false} >
      <Modal.Header closeButton>
        <Modal.Title>{workingTableConfig.name} Dependencies </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          {depRows}
          <Row style={{marginTop: "20px"}}>
          <Col>            
            <Form.Select value={newDependency} onChange={(e) => setNewDependency(e.target.value)}>
              <option>New Dependency</option>              
              {saOptions}
            </Form.Select>
            </Col>
            <Col>
            <Button variant="success" onClick={() => addDependency()}>Add</Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DependencyModal;