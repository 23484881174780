import React, { useState, useEffect } from 'react';
import { Alert, Button, Container, Row, Col, Form, Modal, InputGroup, Badge } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { saveGoldTable } from '../../services/lakehouse-api'
import CodeEditor from '@uiw/react-textarea-code-editor'
import { XCircleFill, Hash } from 'react-bootstrap-icons'
import { RiContactsBookLine } from 'react-icons/ri';

function GoldQueryModal(props) {
  const [workingTable, setWorkingTable] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState([])
  const [errorColumns, setErrorColumns] = useState([])
  const [formatError, setFormatError] = useState(false)

  useEffect(() => {
    setWorkingTable(props.table)
    validateQuery(props.table.sql)
  }, [props.table])

  useEffect(() => {  
    if (workingTable != null)
    validateQuery(workingTable.sql)
  }, [workingTable])

  let handleClose = () => {
    props.setModalVisible("")
    setWorkingTable(null)
  }

  let updateQuery = (query) => {  
    validateQuery(query)  
    const newWorkingTable = { ...workingTable }
    newWorkingTable.sql = query
    setWorkingTable(newWorkingTable)
  }

  let validateQuery = (query) => {
    
    let formatRegex = /SELECT[\s|\n]+(.*)[\s|\n]+FROM[\s|\n]+(.*)[\s|\n]+WHERE[\s|\n]+(.*)[\s|\n]+GROUP BY[\s|\n]+(.*)/gsi;
    let columnsRegex = /SELECT[\s|\n]+(.*)[\s|\n]+FROM/gsi;

    let formatValid = false
    var matches;
    while (matches = formatRegex.exec(query)) {    
      formatValid = true
    }

    if (!formatValid) {
      setFormatError(true)
    }
    else {
      setFormatError(false)
    }

    let gotColumns = false
    var matches;
    while (matches = columnsRegex.exec(query)) {    
      gotColumns = true
      let columnsPart = matches[1] + "\n"            
      let colRegex = /.* as? (\w*)[,\n]+/gmi
      var colMatches
      
      let cols = []
      while (colMatches = colRegex.exec(columnsPart)) {
        if (colMatches[1]) {
          cols.push(colMatches[1])        
        }
        if (colMatches[2]) {
          cols.push(colMatches[2])        
        }

      }

      setSelectedColumns(cols)

      let ec = []
      let fields = []
      if (workingTable != null)
        fields = [...workingTable.fields]

      cols.map((col) => {
        let f = fields.find((t) => t.path == col)
        if (typeof f == 'undefined')
          ec.push(col)
      })

      setErrorColumns(ec)

    }

    if (!gotColumns) {
      setSelectedColumns([])
      setErrorColumns([])
    }
    

  }


  let saveWorkingTable = async () => {
    await saveGoldTable(props.token, workingTable)
    await props.reloadTables()
    handleClose()
  }

  if (workingTable == null) return null

  document.documentElement.setAttribute('data-color-mode', 'light')
  
  let fields = []
  if (workingTable.fields != null)
    fields = [...workingTable.fields]

  let fieldRows = fields.sort((f, s) => {
    if (f.path >= s.path) return 1
    else return -1
  }).map((field) => {
    let bg = "secondary"
    if (selectedColumns.includes(field.path)) {
      bg = "success"
    }
    return <Badge bg={bg} style={{marginLeft: "5px"}}>{field.path}</Badge>
  })
  
  let saveEnabled = true

  let badColumns = errorColumns.map((col) => {
    saveEnabled = false
    return <Badge bg="danger" style={{marginLeft: "5px"}}>{col}</Badge>
  })

  if (formatError != "") {
    saveEnabled = false
  }

  return (
    <Modal
      show={workingTable != null}
      onHide={handleClose}
      backdrop="static"
      keyboard={true}
      fullscreen={false}
      size="lg" >
      <Modal.Header closeButton>
        <Modal.Title>Editing {workingTable.name} Query</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <h5>Destination Columns</h5>
          {fieldRows}{badColumns}
          <h5 style={{marginTop: "25px"}}>Query</h5>
          <Form.Group>
            <CodeEditor
              value={workingTable.sql}
              language="sql"
              placeholder=""
              onChange={(e) => updateQuery(e.target.value)}
              padding={15}
              style={{
                fontSize: 12,
                backgroundColor: "#eee",
                border: "1px solid #ddd",
                minHeight: "500px",                                
                fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
              }}
            />

          </Form.Group>

          {formatError != "" && <Alert variant="danger" style={{marginTop: "20px"}}>Invalid SQL Query. Required Clauses [SELECT, FROM, WHERE, GROUP BY]</Alert>}
          {errorColumns.length > 0 && <Alert variant="danger" style={{marginTop: "20px"}}>Selected Columns Missing From Destination Table (<b>{errorColumns.join(', ')}</b>)</Alert>}

        </Container>

        


      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" disabled={!saveEnabled} onClick={saveWorkingTable}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default GoldQueryModal;