import React, { useState, useEffect } from 'react';
import { Table, Button, Container, Row, Col, Form, Modal, InputGroup } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { saveSilverTable } from '../../services/lakehouse-api'
import { XCircleFill, Hash } from 'react-bootstrap-icons'

function SilverFiltersModal(props) {
  const [workingTable, setWorkingTable] = useState(null);
  const [newFieldName, setNewFieldName] = useState("")
  const [newFieldValue, setNewFieldValue] = useState("")
  const [newOperator, setNewOperator] = useState("")


  useEffect(() => {
    setWorkingTable(props.table)
  }, [props.table])

  let handleClose = () => {
    props.setModalVisible("")
    setWorkingTable(null)
  }
  let removeFilter = (idx) => {
    const newWorkingTable = {...workingTable}
    newWorkingTable.filters = [...workingTable.filters]
    newWorkingTable.filters.splice(idx, 1)
    
    setWorkingTable(newWorkingTable)
  }

  let addFilter = () => {
    const newWorkingTable = {...workingTable}
    
    let newFilter = ""
    
    let field = workingTable.fields.find((f) => f.name == newFieldName)

    if (["is null", "is not null"].includes(newOperator)) {
      newFilter = (newFieldName + " " + newOperator).trim()
    } else if (["_tenant", "_dataType", "_inputType", "_application", "_date"].includes(newFieldName) || field.type == "string") {
      newFilter = (`${newFieldName} ${newOperator} '${newFieldValue}'`).trim()
    }
    else {
      newFilter = (`${newFieldName} ${newOperator} ${newFieldValue}`).trim()
    }
    
    newWorkingTable.filters = [...workingTable.filters, newFilter]        
    setWorkingTable(newWorkingTable)
  }


  let saveWorkingTable = async () => {
    await saveSilverTable(props.token, workingTable)
    await props.reloadTables()
    handleClose()
  }


  if (workingTable == null) return null

  let filterFields = workingTable.filters.map((filter, i) => {

    return <InputGroup className="mb-3">
      <InputGroup.Text id="basic-addon1">{i}</InputGroup.Text>
      <Form.Control locked type="text" value={filter} />
      <Button variant="danger" id="button-addon2" onClick={() => removeFilter(i)}>
        Remove
      </Button>

    </InputGroup>

  })

  let fieldOptions = workingTable.fields.sort((f, s) => {
    if (f.path >= s.path) return 1
    else return -1
  }).map((field) => {
    return <option value={field.path}>{field.path}</option>
  })

  return (
    <Modal
      show={workingTable != null}
      onHide={handleClose}
      backdrop="static"
      keyboard={true}
      fullscreen={false}
      size="lg" >
      <Modal.Header closeButton>
        <Modal.Title>Editing {workingTable.name} Filters</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>

          <Row>
            <Col>
              <div style={{ marginBottom: "50px" }}>
                <h2>Data Filtering</h2>
                <Form>
                  {filterFields}
                  <Row>
                    <Col sm={6}  style={{marginTop: "10px"}}>
                      <Form.Group>
                        <Form.Select value={newFieldName} onChange={(e) => setNewFieldName(e.target.value)}>
                          <option>Choose Source Field</option>
                          <option value="_tenant">_tenant</option>
                          <option value="_dataType">_dataType</option>
                          <option value="_application">_application</option>
                          <option value="_inputType">_inputType</option>
                          <option value="_date">_date</option>
                          <option value="_hour">_hour</option>

                          {fieldOptions}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col sm={6}  style={{marginTop: "10px"}}>
                      <Form.Group>
                        <Form.Select value={newOperator} onChange={(e) => setNewOperator(e.target.value)}>
                          <option>Choose Operator</option>
                          <option value="==">Equals</option>
                          <option value="!=">Not Equals</option>
                          <option value=">">Greater Than</option>
                          <option value=">=">Greater Than Or Equals</option>
                          <option value="<">Less Than</option>
                          <option value="<=">Less Than Or Equals</option>
                          <option value="is null">Is Null</option>
                          <option value="is not null">Is Not Null</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col sm={10} style={{marginTop: "10px"}}>
                      <Form.Group>
                        <Form.Control type="text" placeholder="Enter Value" value={newFieldValue} onChange={(e) => setNewFieldValue(e.target.value)} />
                      </Form.Group>
                    </Col>
                    <Col sm={2} style={{marginTop: "10px"}}>
                      <Form.Group><Button variant="success" id="button-addon2" onClick={() => addFilter()}>
                        Add
                      </Button></Form.Group>
                    </Col>
                  </Row>




                </Form>
              </div>
            </Col>
          </Row>

        </Container>




      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={saveWorkingTable}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SilverFiltersModal;