import React, { useState, useEffect } from 'react';
import { Table, Button, Container, Row, Col, Form, Modal } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { saveSubjectArea } from '../../services/lakehouse-api'

function EditModal(props) {
  const [workingSubjectArea, setWorkingSubjectArea] = useState(null);
  const [token, setToken] = useState("")

  useEffect(() => {
    setWorkingSubjectArea(props.subjectArea)
    setToken(props.token)
  }, [props.subjectArea,props.token])

  if (workingSubjectArea == null) {
    return null
  }

  let updateSubjectArea = (field, value) => {
    let newSubjectArea = {...workingSubjectArea}
    newSubjectArea[field] = value
    setWorkingSubjectArea(newSubjectArea)
  }

  let handleClose = () => {    
    setWorkingSubjectArea(null)
    props.setModalVisible("")
  }

  let saveWorkingSubjectArea = async () => {
    await saveSubjectArea(token, workingSubjectArea)
    await props.reloadData(token)
    handleClose()
  }

  return (
    <Modal
      show={workingSubjectArea != null}
      onHide={handleClose}
      backdrop="static"
      keyboard={true}
      fullscreen={false} >
      <Modal.Header closeButton>
        <Modal.Title>Editing {workingSubjectArea.name} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" placeholder="Name" value={workingSubjectArea.name} onChange={(e) => updateSubjectArea('name', e.target.value)} />
          </Form.Group> 
          <Form.Group>
            <Form.Label>Version</Form.Label>
            <Form.Control type="text" placeholder="Version" value={workingSubjectArea.version} onChange={(e) => updateSubjectArea('version', e.target.value)} />
          </Form.Group> 
          <Form.Group>
            <Form.Label>Package Name</Form.Label>
            <Form.Control type="text" placeholder="Package Name" value={workingSubjectArea.packageName} onChange={(e) => updateSubjectArea('packageName', e.target.value)} />
          </Form.Group> 

        </Container>




      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={saveWorkingSubjectArea}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default EditModal;