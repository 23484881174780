import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Button, Container, Row, Col, Form, Modal, Badge, Table } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import { saveSilverTable } from '../../services/lakehouse-api'
import SilverSettingsModalSource from './silverSettingsModalSource'

function SilverSettingsModal(props) {
  const [workingTable, setWorkingTable] = useState(null);
  const [newDataType, setNewDataType] = useState("");
  const [valid, setValid] = useState(false)
  const [size, setSize] = useState('s');
  useEffect(() => {
    setWorkingTable(props.table)
  }, [props.table])

  let handleClose = () => {
    props.setModalVisible("")
    setWorkingTable(null)
  }

  if (workingTable == null) return null


  let saveWorkingTable = async () => {
    await saveSilverTable(props.token, workingTable)
    await props.reloadTables()
    handleClose()
  }

  let addNewDataType = (name) => {

    let newSource = {
      "name": name,
      "transforms": []
    }
    const newWorkingTable = { ...workingTable }
    newWorkingTable.dataTypes[name] = newSource
    setWorkingTable(newWorkingTable)
  }

  let addNewTransform = (dataType, newTransform) => {
    const updateDataTypes = [...workingTable.dataTypes];
    updateDataTypes.find(i => i.dataType === dataType).transforms.push(newTransform)
    const newWorkingTable = { ...workingTable, dataTypes: updateDataTypes };
    setWorkingTable(newWorkingTable)
  }

  let removeTransform = (dataType, transform) => {
    const updateDataTypes = [...workingTable.dataTypes];
    const updatedTransforms = updateDataTypes.find(i => i.dataType === dataType).transforms

    let i = 0
    while (i < updatedTransforms.length) {
      if (updatedTransforms[i] === transform) {
        updatedTransforms.splice(i, 1);
      } else {
        ++i;
      }
    }

    updateDataTypes.find(i => i.dataType === dataType).transforms = updatedTransforms
    const newWorkingTable = { ...workingTable, dataTypes: updateDataTypes };
    setWorkingTable(newWorkingTable)
  }

  let eventTabs = Object.keys(workingTable.dataTypes).map((dataType) => {
    return <Tab key={dataType} eventKey={dataType} title={dataType}>
      <SilverSettingsModalSource dataType={dataType} setValid={setValid} token={props.token} table={workingTable} addNewTransform={addNewTransform} removeTransform={removeTransform} />
    </Tab>
  })

  let searchOptions = Object.keys(props.dataTypes).map((dt) => {
    let v = props.dataTypes[dt]
    return { name: v.name, value: v.name }

  })

  return (
    <Modal
      show={workingTable != null}
      onHide={handleClose}
      backdrop="static"
      keyboard={true}
      fullscreen={true} >
      <Modal.Header closeButton>
        <Modal.Title>Editing {workingTable.name} Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row>
            <Col>
              <div style={{ marginBottom: "50px" }}>
                <h2>Source Data</h2>
                <p>Data will be streamed into the new table whenever it is received for the following data types. Add any relevant data types and data transformations here.</p>

                <h4>Add New Data Source</h4>
                <div style={{ marginBottom: "50px" }}>
                  <SelectSearch
                    options={searchOptions}
                    value={newDataType}
                    search
                    filterOptions={fuzzySearch}
                    name="dataType"
                    placeholder="Add New Source Data Type"
                    onChange={addNewDataType} />
                </div>

                <h4>Active Data Sources</h4>
                <Tabs variant="pills" defaultActiveKey="profile" className="mb-3">
                  {eventTabs}
                </Tabs>
              </div>


            </Col>
          </Row>

        </Container>




      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" disabled={!valid} onClick={saveWorkingTable}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SilverSettingsModal;

