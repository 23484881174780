import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Button, Container, Row, Col, Form, Modal, Badge, Table } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import { getSchema } from '../../services/lakehouse-api'
import { IoToggle } from 'react-icons/io5'
import { TiSortNumerically, TiSortAlphabetically } from 'react-icons/ti'
import { BsBraces } from 'react-icons/bs'
import { RiBracketsFill } from 'react-icons/ri'

function SilverSettingsModalSource(props) {

  const [workingTable, setWorkingTable] = useState(null)
  const [dataType, setDataType] = useState(null)
  const [selectedFunction, setSelectedFunction] = useState(null)

  useEffect(() => {
    setWorkingTable(props.table)
    reloadTables(props.token, props.dataType)
  }, [props.table, props.token, props.dataType])

  let reloadTables = async (token, dt) => {
    setDataType(await getSchema(token, dt))
  }

  let selectFunction = (field, func) => {
    setSelectedFunction({
      "field": field,
      "function": func
    })
  }


  let updateTransformSource = (destination, source) => {

    let updateTable = { ...workingTable }

    let transforms = updateTable.dataTypes[dataType.name].transforms

    let field = transforms.find((t) => t.destination == destination)

    if (typeof field == 'undefined') {
      let newField = {
        "destination": destination,
        "source": source,
        "options": { "merge": "true" },
        "functions": [{ "name": "copy", "options": {} }]
      }
      transforms.push(newField)
    }
    else {
      field.source = source
      field.functions = [{ "name": "copy", "options": {} }]
    }
    setWorkingTable(updateTable)
  }

  let removeTransform = (destination) => {
    let updateTable = { ...workingTable }

    let transforms = updateTable.dataTypes[dataType.name].transforms

    let index = transforms.findIndex((t) => t.destination == destination)

    transforms.splice(index, 1);

    setSelectedFunction(null)
    setWorkingTable(updateTable)

  }


  let updateTransformOption = (destination, option, value) => {
    let updateTable = { ...workingTable }
    let transforms = updateTable.dataTypes[dataType.name].transforms
    let field = transforms.find((t) => t.destination == destination)
    field.options[option] = String(value)    
    setWorkingTable(updateTable)
  }

  let updateTransformFunctionOption = (destination, func, option, value) => {
    let updateTable = { ...workingTable }
    let transforms = updateTable.dataTypes[dataType.name].transforms
    let field = transforms.find((t) => t.destination == destination)    
    let newFunc = field.functions.find((f) => f.name = func)
    newFunc.options[option] = value
    
    setWorkingTable(updateTable)
  }

  let updateTransformFunction = (destination, functionName, enabled) => {
    let updateTable = { ...workingTable }
    let transforms = updateTable.dataTypes[dataType.name].transforms
    let field = transforms.find((t) => t.destination == destination)
    let funcIndex = field.functions.findIndex((f) => f.name == functionName)

    if (enabled && funcIndex == -1) {

      if (functionName != 'copy') {
        let copyIndex = field.functions.findIndex((f) => f.name == 'copy')
        if (copyIndex >= 0) field.functions.splice(copyIndex, 1)
      }

      field.functions.push({ "name": functionName, "options": {} })
    }
    else if (!enabled && funcIndex >= 0) {
      field.functions.splice(funcIndex, 1)
    }

    if (field.functions.length == 0) {
      field.functions.push({ "name": 'copy', "options": {} })
    }
    setSelectedFunction(null)
    setWorkingTable(updateTable)
  }

  if (workingTable == null || dataType == null) return null

  let transformFunctions = [
    {
      "name": "copy",
      "description": "Simple copy from source to destination",
      "types": {      
        "expression": "expression",
        "constant": "constant",
        "boolean": "boolean",
        "string": "string",
        "integer": "integer",
        "double": "double",
        "struct": "struct",
        "array-struct": "array-struct",
        "array-string": "array-string",
        "array-double": "array-double",
        "array-integer": "array-integer",
      },
      "selectable": false,
      "multiple": false,
      "options": []
    },
    {
      "name": "string.toUpper",
      "description": "Upper case source string",
      "types": {
        "string": "string",
      },
      "selectable": true,
      "multiple": false,
      "options": []
    },
    {
      "name": "string.toLower",
      "description": "Lower case source string",
      "types": {
        "string": "string",
      },
      "selectable": true,
      "multiple": false,
      "options": []
    },
    {
      "name": "url.getHostAndPath",
      "description": "Parse URL host and path from string",
      "types": {
        "string": "string",
      },
      "selectable": true,
      "multiple": false,
      "options": []
    },
    {
      "name": "url.getHost",
      "description": "Parse URL host from string",
      "types": {
        "string": "string",
      },
      "selectable": true,
      "multiple": false,
      "options": []
    },
    {
      "name": "explode",
      "description": "Explode String Array",
      "types": {
        "array-struct": "struct",
        "array-string": "string",
        "array-float": "float",
        "array-integer": "integer",
      },
      "selectable": true,
      "multiple": false,
      "options": []
    },
    {
      "name": "url.isAmpUrl",
      "description": "Is amp url",
      "types": {
        "string": "boolean",
      },
      "selectable": true,
      "multiple": false,
      "options": []
    },
    {
      "name": "ip.isInternalIP",
      "description": "Is internal IP",
      "types": {
        "string": "boolean",
      },
      "selectable": true,
      "multiple": false,
      "options": []
    },
    {
      "name": "toString",
      "description": "Convert To String",
      "types": {
        "boolean": "string",
        "integer": "string",
      },
      "selectable": true,
      "multiple": false,
      "options": []
    },
    {
      "name": "toInt",
      "description": "Convert To Integer",
      "types": {
        "boolean": "integer",
        "string": "integer",
      },
      "selectable": true,
      "multiple": false,
      "options": []
    },
  ]

  let isValid = true

  let destinationRows = workingTable.fields.sort((f, s) => {        
    if (f.path >= s.path) return 1
    else return -1
  }).map((field) => {

    let transforms = workingTable.dataTypes[dataType.name].transforms.find((t) => t.destination == field.path)

    let sourceOptions = dataType.fields.map((sourceField) => {
      return { name: sourceField.path, value: sourceField.path, fieldType: sourceField.type }
    })

    sourceOptions.unshift({ name: "CONSTANT", value: "_constant", fieldType: "constant" })
    sourceOptions.unshift({ name: "SQL EXPRESSION", value: "_expression", fieldType: "expression" })

    let transformSource = ''
    let functions = ''
    let currentType = ""
    let sourceDataTypeParent = {}

    if (typeof transforms != 'undefined') {

      transformSource = transforms.source

      if (transformSource == "_constant") {
        currentType = "constant"
      }
      else if (transformSource == "_expression") {
        currentType = "expression"
      }
      else {
        let sourceDataType = dataType.fields.find((dt) => dt.path == transformSource)
        sourceDataTypeParent = dataType.fields.find((i) => i.path == sourceDataType.parent)

        if (sourceDataTypeParent !== undefined && sourceDataTypeParent.type === "array-struct") {
          currentType = "array-" + sourceDataType.type
        }
        else {
          currentType = sourceDataType.type
        }

      }

      functions = transforms.functions.map((f) => {
        let currentTransform = transformFunctions.find((tf) => tf.name == f.name)
        currentType = currentTransform.types[currentType]
        let variant = "primary"

        if (selectedFunction != null && selectedFunction.function.name == f.name && selectedFunction.field.path == field.path) {
          variant = "success"

        }
        return <Badge style={{ marginRight: "5px", cursor: "pointer" }} bg={variant} onClick={() => selectFunction(field, f)}>{f.name} <span onClick={(e) => { e.stopPropagation(); updateTransformFunction(field.path, f.name, false); } }>x</span></Badge>
      })

    }

    let availableFunctions = transformFunctions.map((f) => {

      if (!f.selectable) {
        return null
      }
      else if (typeof f.types[currentType] == 'undefined') {
        return null
      }
      else if (typeof transforms.functions.find((tf) => f.name == tf.name) != 'undefined' && f.multiple == false) {
        return null
      }
      return <Badge bg={"secondary"} style={{ marginRight: "5px" }} onClick={() => updateTransformFunction(field.path, f.name, true)}>{f.name}</Badge>
    })


    let error = ""
    if (currentType != "" && currentType != "constant" && currentType != "expression" && currentType != field.type) {
      error = <div style={{ color: "red" }}>Types don't match, you could experience data loss.  Expected <b>{field.type}</b> but got <b>{currentType}</b></div>
      isValid = false
    }

    let mergeField = (typeof transforms != 'undefined' && transforms.options.hasOwnProperty("merge")) ? transforms.options["merge"] == "true" : false


    let icon = ""
    
    let bg = "secondary"

    if (workingTable.mergeRules.fields.includes(field.path)) {
      bg = "danger"
    }

    switch (field.type) {
      case "string":
        icon = <Badge bg={bg} title="string"><TiSortAlphabetically style={{ fontSize: "20px" }} /></Badge>
        break
      case "integer":
        icon = <Badge bg={bg} title="integer"><TiSortNumerically style={{ fontSize: "20px" }} /></Badge>
        break
      case "boolean":
        icon = <Badge bg={bg} title="boolean"><IoToggle style={{ fontSize: "20px" }} /></Badge>
        break
      case "struct":
        icon = <Badge bg={bg} title="boolean"><BsBraces style={{ fontSize: "20px" }} /></Badge>
        break
      case "array":
        icon = <Badge bg={bg} title="boolean"><RiBracketsFill style={{ fontSize: "20px" }} /></Badge>
        break
      default:
        icon = <Badge bg={bg} title="default"><RiBracketsFill style={{ fontSize: "20px" }} /></Badge>
        break
  
    }

    
    let fieldOptions = []
    if (selectedFunction != null && selectedFunction.field.path == field.path) {
      let transformConfig = transformFunctions.find((tf) => tf.name == selectedFunction.function.name)

      if (transformConfig.options.length > 0) {

        fieldOptions = transformConfig.options.map((o) => {          
          
          let st = workingTable.dataTypes[dataType.name].transforms.find((t) => t.destination == selectedFunction.field.path)
          
          let sf = st.functions.find((tf) => tf.name == selectedFunction.function.name)
          
          let optionVal = (typeof sf != 'undefined' && typeof sf.options[o.name] != 'undefined') ? sf.options[o.name] : ""          

          switch (o.type) {

            case "constant":
              return <Form.Group>
                <Form.Label>{o.display}</Form.Label>
                <Form.Control style={{width: "250px"}} type="text" value={optionVal} onChange={(e) => updateTransformFunctionOption(selectedFunction.field.path, selectedFunction.function.name, o.name, e.target.value)} />
              </Form.Group>
            case "source":
              return <Form.Group>
                <Form.Label>{o.display}</Form.Label>
                <SelectSearch
                  options={sourceOptions}
                  value={optionVal}
                  search
                  filterOptions={fuzzySearch}
                  onChange={(e) => updateTransformFunctionOption(selectedFunction.field.path, selectedFunction.function.name, o.name, e)}
                />
              </Form.Group>
          }

        })

      }


    }



    return <tr>
      <td id={dataType.name + "_" + workingTable.name + "_" + field.path}>
        <div>{icon} {(field.parent != "") ? (<span style={{ color: "#aaa" }}> {field.parent + "."}</span>) : ""}<b>{field.name}</b></div>
      </td>
      <td>
        <div>
          <div style={{ display: "inline-block", minWidth: "75px", verticalAlign: "top" }}>
            {transformSource != "" && <Button variant="danger" onClick={() => removeTransform(field.path)}>Clear</Button>}
          </div>
          <div style={{ display: "inline-block" }} >
            <SelectSearch
              options={sourceOptions}
              value={transformSource}
              search
              filterOptions={fuzzySearch}
              name="sourceField"
              placeholder="Choose Source Field"
              onChange={(e) => updateTransformSource(field.path, e)} />
            <div style={{ display: "block" }}>
              {transformSource == "_constant" &&
                <Form.Control type="text" placeholder="Enter Constant Value" value={transforms.options["constantValue"]} onChange={(e) => updateTransformOption(field.path, "constantValue", e.target.value)} />
              }
              {transformSource == "_expression" &&
                <Form.Control type="text" placeholder="Enter Expression Value" value={transforms.options["expressionValue"]} onChange={(e) => updateTransformOption(field.path, "expressionValue", e.target.value)} />
              }
            </div>

          </div>          
        </div>
        <div>{error}</div>



      </td >
      <td>
        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Check onClick={(e) => updateTransformOption(field.path, "merge", !mergeField)} checked={mergeField} type="checkbox" label="Overwrite" />
        </Form.Group>
      </td>
      <td>
        <div>{functions}</div>
        <div>{availableFunctions}</div>
      </td>
      <td>
        {fieldOptions != "" &&
          <div>
            <div>{selectedFunction.function.name} Options</div>
            <div>{fieldOptions}</div>
          </div>
        }
      </td>

    </tr >
  })

  props.setValid(isValid)

  return (
    <Container fluid>
      <Table>
        <thead>
          <tr><th>Data Type</th><th style={{ width: "750px" }}>Source Field</th><th>Overwrite Existing Data</th><th>Transformation(s)</th><th>Options</th></tr>
        </thead>
        <tbody>
          {destinationRows}
        </tbody>
      </Table>
    </Container>
  )
}

export default SilverSettingsModalSource