import React, { useState, useEffect } from 'react';
import { Table, Button, Container, Alert, Form, Modal } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import DatePicker from 'react-date-picker'
import { saveBackfillRun, calculateBackfillRunCost } from '../../services/lakehouse-api'

function EditModal(props) {
  const [workingBackfillRun, setWorkingBackfillRun] = useState(null);
  const [tableConfigs, setTableConfigs] = useState(null);  
  const [backfillCost, setBackfillCost] = useState(null)
  const [token, setToken] = useState("")

  useEffect(() => {
    setWorkingBackfillRun(props.backfillRun)
    setTableConfigs(props.tableConfigs)
    setToken(props.token)
  }, [props.backfillRun, props.tableConfigs, props.token])

  useEffect(async () => {    
    if (workingBackfillRun.tableConfigId && workingBackfillRun.startDate && workingBackfillRun.endDate) {
      console.log("running")
      let br = {...workingBackfillRun}
      br.startDate = br.startDate.getFullYear() + '-' + String(br.startDate.getMonth()+1).padStart(2, '0') + '-' + String(br.startDate.getDate()).padStart(2, '0') 
      br.endDate = br.endDate.getFullYear() + '-' + String(br.endDate.getMonth()+1).padStart(2, '0') + '-' + String(br.endDate.getDate()).padStart(2, '0') 
      
      setBackfillCost(Math.round(await calculateBackfillRunCost(token, br)))
    }  
    else {
      setBackfillCost(null)
    }  
  }, [workingBackfillRun])


  if (workingBackfillRun == null || tableConfigs == null) {
    return null
  }

  let updateBackfillRun = async (field, value) => {    
    let newBackfillRun = { ...workingBackfillRun }
    newBackfillRun[field] = value
    setWorkingBackfillRun(newBackfillRun)

  }

  let handleClose = () => {
    setWorkingBackfillRun(null)
  }

  let saveWorkingBackfillRun = async () => {

    let br = {...workingBackfillRun}
    br.startDate = br.startDate.getFullYear() + '-' + String(br.startDate.getMonth()+1).padStart(2, '0') + '-' + String(br.startDate.getDate()).padStart(2, '0') 
    br.endDate = br.endDate.getFullYear() + '-' + String(br.endDate.getMonth()+1).padStart(2, '0') + '-' + String(br.endDate.getDate()).padStart(2, '0') 
    
    await saveBackfillRun(token, br)
    await props.reloadData(token)
    handleClose()
  }

  let bcOptions = tableConfigs.map((bc) => {
    return <option key={bc.id} value={bc.id}>{bc.name} ({bc.zone})</option>
  })

  let chosenTableConfig = tableConfigs.find((bc) => bc.id == workingBackfillRun.tableConfigId)

  
  return (
    <Modal
      show={workingBackfillRun != null}
      onHide={handleClose}
      backdrop="static"
      keyboard={true}
      fullscreen={false} >
      <Modal.Header closeButton>
        <Modal.Title>New Backfill</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Form.Group>
            <Form.Label>Table</Form.Label>
            <Form.Select value={workingBackfillRun.tableConfigId} onChange={(e) => updateBackfillRun("tableConfigId", parseInt(e.target.value))}>
              <option>Choose Table</option>
              {bcOptions}
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Label>Start Date</Form.Label>
            <DatePicker className="form-control" format="y-MM-dd" onChange={(e) => updateBackfillRun("startDate", e)} value={workingBackfillRun.startDate} />
          </Form.Group>
          <Form.Group>
            <Form.Label>End Date</Form.Label>
            <DatePicker className="form-control" format="y-MM-dd" onChange={(e) => updateBackfillRun("endDate", e)} value={workingBackfillRun.endDate} />
          </Form.Group>
          {chosenTableConfig != null && chosenTableConfig.requiresTenants &&
            <Form.Group>
              <Form.Label>Tenants</Form.Label>
              <Form.Check
                type="switch"
                id="custom-tenants"
                label="Customize Tenants"
                onChange={(e) => { (e.target.checked === false) ? updateBackfillRun("tenants", "*") : updateBackfillRun("tenants", "") }}
              />
            </Form.Group>
          }
          {workingBackfillRun.tenants != "*" &&
            <div>
              <div>Enter tenants (comma-separated)</div>
              <Form.Control as="textarea" rows={3} placeholder="Tenants" value={workingBackfillRun.tenants} onChange={(e) => updateBackfillRun('tenants', e.target.value)} />
            </div>
          }



          {chosenTableConfig != null && chosenTableConfig.requiresDomains &&
            <Form.Group>
              <Form.Label>Domains</Form.Label>
              <Form.Check
                type="switch"
                id="custom-domains"
                label="Customize Domains"
                onChange={(e) => { (e.target.checked === false) ? updateBackfillRun("domains", "*") : updateBackfillRun("domains", "") }}
              />
            </Form.Group>
          }
          {workingBackfillRun.domains != "*" &&
            <div>
              <div>Enter domains (comma-separated)</div>
              <Form.Control as="textarea" rows={3} placeholder="Domains" value={workingBackfillRun.domains} onChange={(e) => updateBackfillRun('domains', e.target.value)} />
            </div>
          }
          <Form.Group>
            <Form.Label>Dependent Backfills</Form.Label>
            <Form.Check
              type="switch"
              id="run-dependent"
              label="Run Dependent Backfills"
              onChange={(e) => updateBackfillRun("runDependencies", e.target.checked)}
            />
          </Form.Group>

        </Container>

        {backfillCost && <Alert variant="danger" style={{marginTop: "10px"}}><b>This will cost an estimated ${Math.round(backfillCost * 100) / 100}</b><p style={{marginTop: "5px"}}>This amount is only an estimate based on S3, EC2 and Databricks charges and should be used as a guideline only.</p></Alert>}


      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={saveWorkingBackfillRun}>
          Schedule Backfill
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default EditModal;