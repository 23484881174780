import React, { useEffect, useState } from 'react';
import { Container, Table, Form, Button } from 'react-bootstrap'
import { ArrowRepeat } from 'react-bootstrap-icons'
import { toggleTenant } from '../../services/lakehouse-api'

function TenantEditor(props) {

  const [tenants, setTenants] = useState([])
  const [token, setToken] = useState("")
  const [filter, setFilter] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setToken(props.token)
    setTenants(props.tenants)
    setLoading(props.loading)
  }, [props.tenants, props.token, props.loading])

  let toggle = (sourceUid) => {
    toggleTenant(token, sourceUid)
    let newTenants = [...tenants]
    let t = newTenants.find((t) => t.sourceUid == sourceUid)
    t.enabled = !t.enabled
    setTenants(newTenants)
  }

  let filteredTenants = tenants

  if (filter != '') {
    filteredTenants = filteredTenants.filter((t) => t.sourceUid.toLowerCase().includes(filter.toLowerCase()) || t.legacyTenantId.toLowerCase().includes(filter.toLowerCase()) || t.workspaceName.toLowerCase().includes(filter.toLowerCase()) ||  t.tags.includes(filter))
  }

  let tenantRows = filteredTenants.sort((a, b) => {
   
    // sort by enabled
    if (a.enabled == true && b.enabled == false) {
      return 1
    }
    if (a.enabled == false && b.enabled == true) {
      return -1
    }

    // sort by workspace name
    if (a.workspaceName > b.workspaceName) {
      return 1
    }
    else {
      return -1
    }
  }).map((sa) => {
    return <tr key={sa.sourceUid}>
      <td>{sa.workspaceName}</td>
      <td>{sa.sourceUid}</td>
      <td>{sa.legacyTenantId}</td>
      <td>{sa.tags}</td>
      <td>
        <Form.Group>
          <Form.Check
            type="switch"
            id="custom-tenants"
            label="Enabled"
            checked={sa.enabled}
            onChange={() => toggle(sa.sourceUid)}
          />
        </Form.Group>
      </td></tr>
  })

  return (
    <Container fluid style={{ maxHeight: "100vh", overflow: "scroll" }}>
      <div style={{ margin: "10px", textAlign: "right" }}>
        
      </div>

      <h2>Tenants</h2>
      <Form.Group>
        <Form.Control type="text" placeholder="Filter" value={filter} onChange={(e) => setFilter(e.target.value)} />
      </Form.Group>      
      <Table>
        <thead>
          <tr>
            <th>Workspace Name</th>
            <th>Source Uid</th>
            <th>Legacy Tenant Id</th>
            <th>Tags</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{tenantRows}</tbody>
      </Table>
    </Container>
  );
}

export default TenantEditor