const host = process.env.REACT_APP_LAKEHOUSE_API_URL

const getDomains = async(token) => {  
  let url = `${host}/domain`;

  let options = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',    
    redirect: 'follow',
    referrerPolicy: 'no-referrer', 
    headers: { 
      'Authorization': `Bearer ${token}`
    }   
  }

  return await fetch(url, options).then(async (r)=> {
    let response = await r.json()    
    return response;
  })

}

const updateDomain = async(token, domain) => {  
  let url = `${host}/domain`;

  let options = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',    
    redirect: 'follow',
    referrerPolicy: 'no-referrer', 
    headers: { 
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(domain)
  }

  return await fetch(url, options).then(async (r)=> {  
    return true;
  })

}

const getSubjectAreas = async(token) => {  
  let url = `${host}/subjectarea`;

  let options = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',    
    redirect: 'follow',
    referrerPolicy: 'no-referrer', 
    headers: { 
      'Authorization': `Bearer ${token}`
    }   
  }

  return await fetch(url, options).then(async (r)=> {
    let response = await r.json()    
    return response;
  })

}

const saveSubjectArea = async(token, subjectArea) => {

  let url = `${host}/subjectarea`;

  let options = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',  
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'      
    },  
    redirect: 'follow',
    referrerPolicy: 'no-referrer',    
    body: JSON.stringify(subjectArea)
  }

  return await fetch(url, options).then(async (r)=> {  
    return true;
  })

}

const getSubjectAreaDependencies = async(token, id) => {  
  let url = `${host}/subjectareadependency?subjectAreaId=${id}`;

  let options = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',    
    redirect: 'follow',
    referrerPolicy: 'no-referrer',    
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  }

  return await fetch(url, options).then(async (r)=> {
    let response = await r.json()    
    return response;
  })

}

const saveSubjectAreaDependency = async(token, subjectAreaDependency) => {

  let url = `${host}/subjectareadependency`;

  let options = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',  
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'      
    },  
    redirect: 'follow',
    referrerPolicy: 'no-referrer',    
    body: JSON.stringify(subjectAreaDependency)
  }

  return await fetch(url, options).then(async (r)=> {  
    return true;
  })

}

const deleteSubjectAreaDependency = async(token, subjectAreaDependency) => {

  let url = `${host}/subjectareadependency`;

  let options = {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',  
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'      
    },  
    redirect: 'follow',
    referrerPolicy: 'no-referrer',    
    body: JSON.stringify(subjectAreaDependency)
  }

  return await fetch(url, options).then(async (r)=> {  
    return true;
  })

}

const getTableConfigs = async(token) => {  
  let url = `${host}/tableconfig`;

  let options = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',    
    redirect: 'follow',
    referrerPolicy: 'no-referrer',    
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  }

  return await fetch(url, options).then(async (r)=> {
    let response = await r.json()    
    return response;
  })

}

const saveTableConfig = async(token, backfillConfig) => {

  let url = `${host}/tableconfig`;

  let options = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',  
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'      
    },  
    redirect: 'follow',
    referrerPolicy: 'no-referrer',    
    body: JSON.stringify(backfillConfig)
  }

  return await fetch(url, options).then(async (r)=> {  
    return true;
  })

}

const getTableConfigDependencies = async(token, id) => {  
  let url = `${host}/tableconfigdependency?tableConfigId=${id}`;

  let options = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',    
    redirect: 'follow',
    referrerPolicy: 'no-referrer',    
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  }

  return await fetch(url, options).then(async (r)=> {
    let response = await r.json()    
    return response;
  })

}

const saveTableConfigDependency = async(token, tableConfigDependency) => {

  let url = `${host}/tableconfigdependency`;

  let options = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',  
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'      
    },  
    redirect: 'follow',
    referrerPolicy: 'no-referrer',    
    body: JSON.stringify(tableConfigDependency)
  }

  return await fetch(url, options).then(async (r)=> {  
    return true;
  })

}

const deleteTableConfigDependency = async(token, tableConfigDependency) => {

  let url = `${host}/tableconfigdependency`;

  let options = {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',  
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'      
    },  
    redirect: 'follow',
    referrerPolicy: 'no-referrer',    
    body: JSON.stringify(tableConfigDependency)
  }

  return await fetch(url, options).then(async (r)=> {  
    return true;
  })

}

const saveBackfillRun = async(token, backfillRun) => {

  let url = `${host}/backfillrun`;

  let options = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',  
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'      
    },  
    redirect: 'follow',
    referrerPolicy: 'no-referrer',    
    body: JSON.stringify(backfillRun)
  }

  return await fetch(url, options).then(async (r)=> {  
    return true;
  })

}


const calculateBackfillRunCost = async(token, backfillRun) => {

  let url = `${host}/backfillrun?cost`;

  let options = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',  
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'      
    },  
    redirect: 'follow',
    referrerPolicy: 'no-referrer',    
    body: JSON.stringify(backfillRun)
  }

  return await fetch(url, options).then(async (r)=> {  
    let response = await r.text()    
    return response;
  })

}


const cancelBackfillRun = async(token, backfillRun) => {

  let url = `${host}/backfillrun`;

  let options = {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',  
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'      
    },  
    redirect: 'follow',
    referrerPolicy: 'no-referrer',    
    body: JSON.stringify(backfillRun)
  }

  return await fetch(url, options).then(async (r)=> {  
    return true;
  })

}

const getBackfillRunStatuses = async(token, id) => {  
  let url = `${host}/backfillrunstatus`;

  let options = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',    
    redirect: 'follow',
    referrerPolicy: 'no-referrer',    
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  }

  return await fetch(url, options).then(async (r)=> {
    let response = await r.json()    
    return response;
  })

}

const getSilverTables = async(token, workspaceId) => {  
  let url = `${host}/silver?workspaceId=${workspaceId}`;

  let options = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',    
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    headers: {
      'Authorization': `Bearer ${token}`,
    }    
  }

  return await fetch(url, options).then(async (r)=> {
    let response = await r.json()    
    return response;
  })

}

const saveSilverTable = async(token, table) => {  
  let url = `${host}/silver`

  let options = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',  
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,      
    },  
    redirect: 'follow',
    referrerPolicy: 'no-referrer',    
    body: JSON.stringify(table)
  }

  return await fetch(url, options).then(async (r)=> {
    
    return true;
  })

}


const getGoldTables = async(token, workspaceId) => {  
  let url = `${host}/gold?workspaceId=${workspaceId}`;

  let options = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',    
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    headers: {
      'Authorization': `Bearer ${token}`,
    }    
  }

  return await fetch(url, options).then(async (r)=> {
    let response = await r.json()    
    return response;
  })

}

const saveGoldTable = async(token, table) => {  
  let url = `${host}/gold`

  let options = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',  
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,      
    },  
    redirect: 'follow',
    referrerPolicy: 'no-referrer',    
    body: JSON.stringify(table)
  }

  return await fetch(url, options).then(async (r)=> {
    
    return true;
  })

}

const getSchemaList = async(token) => {  
  let url = `${host}/schema`;

  let options = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',    
    redirect: 'follow',
    referrerPolicy: 'no-referrer',  
    timeout: 120000,
    headers: {
      'Authorization': `Bearer ${token}`,
    } 
  }

  return await fetch(url, options).then(async (r)=> {
    let response = await r.json()    

    let dtObject = {}

    let t = response
  
    t.map((rr) => {
      if (rr.fields !== null) {
        dtObject[rr.name] = rr
      }
      
    })        
    return dtObject;
  })

}

const getSchema = async(token, name) => {
  let url = `${host}/schema?name=` + name;

  let options = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',    
    redirect: 'follow',
    referrerPolicy: 'no-referrer',  
    timeout: 120000,
    headers: {
      'Authorization': `Bearer ${token}`,
    } 
  }

  return await fetch(url, options).then(async (r)=> {
    let response = await r.json()    

    return response

  })

}

const saveSchema = async(token, schema) => {
  let url = `${host}/schema`;

  let options = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(schema)
  }

  return await fetch(url, options).then(async (r)=> {
    return true;
  })

}

const getWorkspaces = async(token) => {  
  let url = `${host}/workspace`;

  let options = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',    
    redirect: 'follow',
    referrerPolicy: 'no-referrer',    
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  }

  return await fetch(url, options).then(async (r)=> {
    let response = await r.json()    
    return response;
  })

}

const saveWorkspace = async(token, workspace) => {
  let url = `${host}/workspace`;

  let options = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(workspace)
  }

  return await fetch(url, options).then(async (r)=> {
    return true;
  })

}

const getTenants = async(token) => {  
  let url = `${host}/tenant`;

  let options = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',    
    redirect: 'follow',
    referrerPolicy: 'no-referrer',    
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  }

  return await fetch(url, options).then(async (r)=> {
    let response = await r.json()    
    return response;
  })

}

const toggleTenant = async(token, sourceUid) => {  
  let url = `${host}/tenant?sourceUid=${sourceUid}`;

  let options = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',    
    redirect: 'follow',
    referrerPolicy: 'no-referrer',    
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  }

  return await fetch(url, options).then(async (r)=> {
    return true;
  })

}


export { 
  getSubjectAreas, 
  saveSubjectArea, 
  getSubjectAreaDependencies, 
  deleteSubjectAreaDependency, 
  saveSubjectAreaDependency, 
  getTableConfigs,
  saveTableConfig,
  getTableConfigDependencies,
  saveTableConfigDependency,
  deleteTableConfigDependency,
  saveBackfillRun,
  getBackfillRunStatuses,
  cancelBackfillRun,
  getSilverTables,
  getSchemaList,
  saveSilverTable,
  getSchema,
  getTenants,
  toggleTenant,
  getDomains,
  updateDomain,
  getWorkspaces,
  calculateBackfillRunCost,
  getGoldTables,
  saveGoldTable,
  saveSchema,
  saveWorkspace,
}
