import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Navbar, Nav, NavDropdown, Form, Button, FormGroup, Alert } from 'react-bootstrap'
import { HiOutlineRefresh } from 'react-icons/hi'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import SubjectAreaEditor from './pages/subjectAreaEditor/subjectAreaEditor'
import TableConfigEditor from './pages/tableConfigEditor/tableConfigEditor'
import BackfillRunEditor from './pages/backfillRunEditor/backfillRunEditor'
import TenantEditor from './pages/tenantEditor/tenantEditor'
import DomainEditor from './pages/domainEditor/domainEditor'
import SchemaEditor from './pages/schemaEditor/schemaEditor'
import SilverTableEditor from './pages/silverTableEditor/silverTableEditor'
import GoldTableEditor from './pages/goldTableEditor/goldTableEditor'
import WorkspaceEditor from './pages/workspaceEditor/workspaceEditor'
import { getWorkspaces, getDomains, getSubjectAreas, getTableConfigs, getBackfillRunStatuses, getSilverTables, getGoldTables, getSchemaList, getTenants } from './services/lakehouse-api'
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from './login.js'
import { GearFill } from 'react-bootstrap-icons'
import jwt_decode from "jwt-decode";

function App() {

  const [page, setPage] = useState("backfillruns");
  const [domains, setDomains] = useState([])
  const [subjectAreas, setSubjectAreas] = useState([])
  const [workspaces, setWorkspaces] = useState([])
  const [tableConfigs, setTableConfigs] = useState([]) 
  const [backfillRuns, setBackfillRuns] = useState([])
  const [silverTables, setSilverTables] = useState([])
  const [goldTables, setGoldTables] = useState([])
  const [workspace, setWorkspace] = useState()
  const [dataTypes, setDataTypes] = useState([])
  const [tenants, setTenants] = useState([])
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState("")
  const [isAdmin, setIsAdmin] = useState(false)
  const [isPOC, setIsPOC] = useState(false)

  useEffect(async () => {
    let accessToken = await getAccessTokenSilently()
    setToken(accessToken)

    let d = jwt_decode(accessToken)

    if (d['https://email'] == "eshelton@redventures.com" || d['https://email'] == "gdevarajan@redventures.com") {
      setIsPOC(true)
    }
    
    if (d.permissions.includes("admin")) {
      setIsAdmin(true)
    }


  }, [])

  useEffect(async () => {
    if (token != "") {
      await reloadData()
    }
  }, [token])

  useEffect(async () => {
    setSilverTables(await getSilverTables(token, workspace))
    setGoldTables(await getGoldTables(token, workspace))
  }, [workspace])


  let reloadData = async () => {
    setLoading(true)
    setWorkspaces(await getWorkspaces(token))
    setDomains(await getDomains(token))
    setSubjectAreas(await getSubjectAreas(token))
    setTableConfigs(await getTableConfigs(token))
    setBackfillRuns(await getBackfillRunStatuses(token))
    setSilverTables(await getSilverTables(token, workspace))
    setGoldTables(await getGoldTables(token, workspace))
    setDataTypes(await getSchemaList(token))
    setTenants(await getTenants(token))
    setLoading(false)
  }

  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  
  if (!isAuthenticated) {
    return <LoginButton />
  }

  let selectedWorkspace = null

  let workspaceOptions = workspaces.map((w) => {
    if (w.id == workspace) {
      selectedWorkspace = w
    }
    return <option value={w.id}>{w.name}</option>
  })

  return (
    <div className="App">

      <Navbar bg="dark" variant="dark">
        <Container fluid>
          <Navbar.Brand href="#home">            
            
            <img src="/delta.png" style={{height: "30px", width: "30px"}} />
            <span style={{ color: "red", fontWeight: "bold", fontSize: "1.2em", marginLeft: "-10px", fontFamily: "Courier" }}>RV</span> 
            <span style={{ color: "white", fontWeight: "bold"}}>Lakehouse</span>
          </Navbar.Brand>

          <Form className="d-flex">
          {isPOC && <FormGroup className="pe-3">
              <Form.Select value={workspace} onChange={(e) => setWorkspace(e.target.value)}>
                <option>Choose Workspace</option>
                {workspaceOptions}
              </Form.Select>
            </FormGroup> }
          <Button variant="secondary" disabled={loading} onClick={reloadData} style={{wordWrap: "none"}}><HiOutlineRefresh style={{marginTop: "-2px"}}/></Button>
          </Form>

        </Container>
      </Navbar>

      <Container fluid>
        <Row>
          <Col sm={12} md={4} lg={2} style={{ backgroundColor: "#333", color: "white", padding: "0px", textAlign: "left" }}>
            <div style={{ cursor: "pointer", padding: "20px", margin: "10px 20px", borderRadius: "5px", backgroundColor: page == "backfillruns" ? '#111' : 'inherit', fontWeight: page == "backfillruns" ? 'bold' : 'normal' }} onClick={() => setPage("backfillruns")} >
              CDW Backfills
            </div>
            {isPOC && <div style={{ cursor: "pointer", padding: "20px", margin: "10px 20px", borderRadius: "5px", backgroundColor: page == "silvertables" ? '#111' : 'inherit', fontWeight: page == "silvertables" ? 'bold' : 'normal' }} onClick={() => setPage("silvertables")} >
              Lakehouse Streamed Tables
            </div>}
            {isPOC && <div style={{ cursor: "pointer", padding: "20px", margin: "10px 20px", borderRadius: "5px", backgroundColor: page == "goldtables" ? '#111' : 'inherit', fontWeight: page == "goldtables" ? 'bold' : 'normal' }} onClick={() => setPage("goldtables")} >
              Lakehouse Reporting Tables
            </div>}

            {isAdmin &&
            <NavDropdown title={<span style={{color: "#eee"}}><GearFill style={{ marginTop: "-2px" }} /> Admin Settings</span>} style={{position: "fixed", bottom: "0px", margin: "20px 10px", color: "white", backgroundColor: "#333"}}>
              <NavDropdown.Item disabled style={{color: "black", fontWeight: "bold"}}>CDW</NavDropdown.Item>
              <NavDropdown.Item onClick={() => setPage("domains")}>Domains</NavDropdown.Item>
              <NavDropdown.Item onClick={() => setPage("subjectareas")}>Subject Areas</NavDropdown.Item>
              <NavDropdown.Item onClick={() => setPage("tableconfigs")}>Tables</NavDropdown.Item>
              
              <NavDropdown.Divider />
              <NavDropdown.Item disabled style={{color: "black", fontWeight: "bold"}}>Lakehouse</NavDropdown.Item>              
              <NavDropdown.Item onClick={() => setPage("schemas")}>Schemas</NavDropdown.Item>
              <NavDropdown.Item onClick={() => setPage("tenants")}>Tenants</NavDropdown.Item>
              <NavDropdown.Item onClick={() => setPage("workspaces")}>Workspaces</NavDropdown.Item>
            </NavDropdown>
            }
          </Col>
          <Col style={{ height: "100vh", padding: "0px" }}>
            {isAdmin && page == "subjectareas" && <SubjectAreaEditor token={token} loading={loading} subjectAreas={subjectAreas} reloadData={reloadData} />}
            {isAdmin && page == "tableconfigs" && <TableConfigEditor token={token} loading={loading} tableConfigs={tableConfigs} subjectAreas={subjectAreas} reloadData={reloadData} />}
            {isAdmin && page == "tenants" && <TenantEditor token={token} loading={loading} tenants={tenants} reloadData={reloadData} />}
            {isAdmin && page == "domains" && <DomainEditor token={token} loading={loading} domains={domains} reloadData={reloadData} />}
            {isAdmin && page == "schemas" && <SchemaEditor token={token} loading={loading} dataTypes={dataTypes} reloadData={reloadData} />}
            {isAdmin && page == "workspaces" && <WorkspaceEditor token={token} loading={loading} workspaces={workspaces} reloadData={reloadData} />}
            {page == "backfillruns" && <BackfillRunEditor isAdmin={isAdmin} token={token} loading={loading} backfillRuns={backfillRuns} tableConfigs={tableConfigs} reloadData={reloadData} />}
            {page == "silvertables" && <SilverTableEditor token={token} loading={loading} workspace={selectedWorkspace} silverTables={silverTables} dataTypes={dataTypes} reloadData={reloadData} />}
            {page == "goldtables" && <GoldTableEditor token={token} loading={loading} workspace={selectedWorkspace} goldTables={goldTables} dataTypes={dataTypes} reloadData={reloadData} />}
          </Col>
        </Row>
      </Container>

      <Alert show={loading} style={{position: "absolute", width: "400px", top: "50px", left: "50%", marginLeft: "-200px"}}>Refreshing Data From Database, Please Wait</Alert>
    </div>
  );
}

export default App;
