import { useAuth0 } from "@auth0/auth0-react";
import { Button, Spinner } from 'react-bootstrap'
import React from "react";

const LoginButton = () => {
  const { loginWithRedirect, isLoading } = useAuth0();  
  return (
    <div style={{textAlign: "center", backgroundColor: "#333", minHeight: "100vh", paddingTop: "200px", backgroundImage: "url('/delta.png')", backgroundRepeat: "no-repeat", backgroundPosition: "center 150px", backgroundSize: "200px 200px"}}>
      <div style={{margin: "10px"}}><b style={{ color: "red", fontWeight: "bold", fontFamily: "Courier", fontSize: "1.9em" }}>RV</b> <span style={{ color: "white", fontWeight: "bold", fontSize: "1.7em",  }}>Lakehouse</span></div>
      {isLoading && 
      <Spinner animation="border" role="status" variant="info">
        <span className="visually-hidden">Loading...</span>
      </Spinner>}
      
      {!isLoading && <Button size="lg" onClick={() => loginWithRedirect()}>Log In</Button>}
    </div>
  
  )
};

export default LoginButton;