import React, { useState, useEffect } from 'react';
import { Table, Button, Container, Row, Col, Form, Modal } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { deleteSubjectAreaDependency, saveSubjectAreaDependency, getSubjectAreaDependencies } from '../../services/lakehouse-api'

function DependencyModal(props) {
  const [subjectAreas, setSubjectAreas] = useState(null);
  const [workingSubjectArea, setWorkingSubjectArea] = useState(null);
  const [dependencies, setDependencies] = useState(null);
  const [newDependency, setNewDependency] = useState(null);
  const [token, setToken] = useState("")

  useEffect(async () => {
    setToken(props.token)
    setDependencies(await getSubjectAreaDependencies(props.token, props.subjectArea.id))
    setWorkingSubjectArea(props.subjectArea)
    setSubjectAreas(props.subjectAreas)
  }, [props.subjectArea, props.subjectArea, props.token])

  if (dependencies == null || workingSubjectArea == null || subjectAreas == null) {
    return null
  }

  let handleClose = () => {
    setWorkingSubjectArea(null)
    props.setModalVisible("")
  }

  let deleteDependency = async (dep) => {
    let deps = [...dependencies]

    let index = deps.findIndex((d) => d.dependentSubjectAreaId == dep.dependentSubjectAreaId)
    deps.splice(index, 1)

    setDependencies(deps)
    await deleteSubjectAreaDependency(token, dep)
  }

  let addDependency = async () => {
    
    if (newDependency == "") return false
    
    let deps = [...dependencies]

    let newDep = {
      subjectAreaId: workingSubjectArea.id,
      dependentSubjectAreaId: parseInt(newDependency)
    }

    deps.push(newDep)
    setDependencies(deps)
    await saveSubjectAreaDependency(token, newDep)
  }

  let saOptions = subjectAreas.map((sa) => {
    if (dependencies.find((dsa) => dsa.dependentSubjectAreaId == sa.id) || workingSubjectArea.id == sa.id) return null
    return <option value={sa.id}>{sa.name}</option>
  })


  let depRows = dependencies.map((d) => {

    let dsa = subjectAreas.find((sa) => sa.id == d.dependentSubjectAreaId)
    return <Row>
      <Col>{dsa.name}</Col>
      <Col><Button variant="danger" onClick={() => deleteDependency(d)}>Remove</Button></Col>
    </Row>
  })

  return (
    <Modal
      show={workingSubjectArea != null}
      onHide={handleClose}
      backdrop="static"
      keyboard={true}
      fullscreen={false} >
      <Modal.Header closeButton>
        <Modal.Title>{workingSubjectArea.name} Dependencies </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          {depRows}
          <Row style={{marginTop: "20px"}}>
          <Col>            
            <Form.Select value={newDependency} onChange={(e) => setNewDependency(e.target.value)}>
              <option>New Dependency</option>              
              {saOptions}
            </Form.Select>
            </Col>
            <Col>
            <Button variant="success" onClick={() => addDependency()}>Add</Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DependencyModal;