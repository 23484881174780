import React, { useState, useEffect } from 'react';
import { Table, Button, Container, Row, Col, Form, Modal } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { saveTableConfig } from '../../services/lakehouse-api'

function EditModal(props) {
  const [workingTableConfig, setWorkingTableConfig] = useState(null);
  const [subjectAreas, setSubjectAreas] = useState(null);
  const [token, setToken] = useState("")

  useEffect(() => {
    setWorkingTableConfig(props.tableConfig)
    setSubjectAreas(props.subjectAreas)
    setToken(props.token)
  }, [props.tableConfig, props.token])

  if (workingTableConfig == null) {
    return null
  }

  let updateTableConfig = (field, value) => {
    let newTableConfig = { ...workingTableConfig }
    newTableConfig[field] = value
    setWorkingTableConfig(newTableConfig)
  }

  let handleClose = () => {
    setWorkingTableConfig(null)
    props.setModalVisible("")
  }

  let saveWorkingTableConfig = async () => {
    await saveTableConfig(token, workingTableConfig)
    await props.reloadData(token)
    handleClose()
  }

  let saOptions = subjectAreas.map((sa) => {
    return <option key={sa.id} value={sa.id}>{sa.name}</option>
  })

  return (
    <Modal
      show={workingTableConfig != null}
      onHide={handleClose}
      backdrop="static"
      keyboard={true}
      fullscreen={false} >
      <Modal.Header closeButton>
        <Modal.Title>Editing {workingTableConfig.name} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" placeholder="Name" value={workingTableConfig.name} onChange={(e) => updateTableConfig("name", e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Subject Area</Form.Label>
            <Form.Select value={workingTableConfig.subjectAreaId} onChange={(e) => updateTableConfig("subjectAreaId", parseInt(e.target.value))}>
              <option>Subject Area</option>
              {saOptions}
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Label>Additional Lookback Days</Form.Label>
            <Form.Select value={workingTableConfig.lookbackDays} onChange={(e) => updateTableConfig("lookbackDays", parseInt(e.target.value))}>
              <option>Additional Lookback Days</option>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>              
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Label>Table Name</Form.Label>
            <Form.Control type="text" placeholder="Table Name" value={workingTableConfig.tableName} onChange={(e) => updateTableConfig("tableName", e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Resource Config</Form.Label>
            <Form.Control type="text" placeholder="Resource Config" value={workingTableConfig.resourceConfig} onChange={(e) => updateTableConfig("resourceConfig", e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>CDM SDK Version</Form.Label>
            <Form.Control type="text" placeholder="CDM SDK Version" value={workingTableConfig.cdmSDKVersion} onChange={(e) => updateTableConfig("cdmSDKVersion", e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Requires Tenants</Form.Label>
            <Form.Select value={workingTableConfig.requiresTenants} onChange={(e) => updateTableConfig("requiresTenants", (e.target.value === 'true'))}>
              <option>Requires Tenants</option>
              <option value="true">true</option>
              <option value="false">false</option>
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Label>Requires Domains</Form.Label>
            <Form.Select value={workingTableConfig.requiresDomains} onChange={(e) => updateTableConfig("requiresDomains", (e.target.value === 'true'))}>
              <option>Requires Domains</option>
              <option value="true">true</option>
              <option value="false">false</option>
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Label>Force Sequential</Form.Label>
            <Form.Select value={workingTableConfig.forceSequential} onChange={(e) => updateTableConfig("forceSequential", (e.target.value === 'true'))}>
              <option>Force Sequential</option>
              <option value="true">true</option>
              <option value="false">false</option>
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Label>Zone</Form.Label>
            <Form.Select value={workingTableConfig.zone} onChange={(e) => updateTableConfig("zone", e.target.value)}>
              <option>Zone</option>
              <option value="cdw">cdw</option>
              <option value="usl">usl</option>
              <option value="reporting">reporting</option>
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Label>Daily Cost Estimate</Form.Label>
            <Form.Control type="text" placeholder="Daily Cost Estimate" value={workingTableConfig.costEstimate} onChange={(e) => updateTableConfig("costEstimate", parseInt(e.target.value == "" ? 0 : e.target.value))} />
          </Form.Group>

        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={saveWorkingTableConfig}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default EditModal;