import React, { useEffect, useState } from 'react';
import { Container, Table, Form, Button, Modal } from 'react-bootstrap'
import { ArrowRepeat } from 'react-bootstrap-icons'
import { updateDomain } from '../../services/lakehouse-api'

function DomainEditor(props) {

  const [domains, setDomains] = useState([])
  const [token, setToken] = useState("")
  const [filter, setFilter] = useState("")
  const [loading, setLoading] = useState(false)
  const [showAddDomainModal, setShowAddDomainModal] = useState(false)
  const [newDomainName, setNewDomainName] = useState("")

  useEffect(() => {
    setToken(props.token)
    setDomains(props.domains)
    setLoading(props.loading)
  }, [props.domains, props.token, props.loading])

  let toggle = (domain) => {    
    let newDomains = [...domains]
    let d = newDomains.find((d) => d.domain == domain)
    d.enabled = !d.enabled

    updateDomain(token, d)

    setDomains(newDomains)
  }

  let addNewDomain = () => {
    let newDomains = [...domains]
    let d = {
      domain: newDomainName,
      enabled: true
    }
    newDomains.push(d)
    updateDomain(token, d)
    setDomains(newDomains)
    setShowAddDomainModal(false)
    setNewDomainName("")
  }

  let filteredDomains = domains

  if (filter != '') {
    filteredDomains = filteredDomains.filter((d) => d.domain.toLowerCase().includes(filter) )
  }

  let domainRows = filteredDomains.sort((a, b) => {
   
    // sort by enabled
    if (a.enabled == true && b.enabled == false) {
      return 1
    }
    if (a.enabled == false && b.enabled == true) {
      return -1
    }

    // sort by domain name
    if (a.domain > b.domain) {
      return 1
    }
    else {
      return -1
    }
  }).map((d) => {
    return <tr key={d.domain}>
      <td>{d.domain}</td>
      <td>
        <Form.Group>
          <Form.Check
            type="switch"            
            label="Enabled"
            checked={d.enabled}
            onChange={() => toggle(d.domain)}
          />
        </Form.Group>
      </td></tr>
  })

  return (
    <Container fluid style={{ maxHeight: "calc(100vh-60px)", overflow: "scroll" }}>
      <div style={{ margin: "10px", textAlign: "right" }}>        
        <Button variant="primary" onClick={() => { setShowAddDomainModal(true) }}>Add Domain</Button>
      </div>

      <h2>Domains</h2>
      <Form.Group>
        <Form.Control type="text" placeholder="Filter" value={filter} onChange={(e) => setFilter(e.target.value)} />
      </Form.Group>      
      <Table>
        <thead>
          <tr>
            <th>Domain</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{domainRows}</tbody>
      </Table>


      <Modal
      show={showAddDomainModal}
      onHide={() => {setShowAddDomainModal(false)}}
      backdrop="static"
      keyboard={true}
      fullscreen={false} >
      <Modal.Header closeButton>
        <Modal.Title>Add Domain </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Form.Group>            
            <Form.Control type="text" placeholder="Domain Name" value={newDomainName} onChange={(e) => setNewDomainName(e.target.value)} />
          </Form.Group> 
        </Container>




      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => {setShowAddDomainModal(false)}}>
          Close
        </Button>
        <Button variant="primary" onClick={() => {  addNewDomain() }}>
          Add Domain
        </Button>
      </Modal.Footer>
    </Modal>


    </Container>
  );
}

export default DomainEditor